import React from "react"
import ResponsiveImage from "components/ResponsiveImage/ResponsiveImage"
import Table from "components/Blocks/Table/Table"

export default function MerContent({ content }) {

    function getContentComponent(content) {
        switch (content.meta.type) {
            case "mer.ImageContentBlock":
                return <div className="map-mer-content map-mer-image p-2"><ResponsiveImage image={content} fullWidth={true} title={content.title} /></div>
            case "mer.TableContentBlock":
                return <div className="map-mer-content map-mer-image p-2"><Table table={content} /></div>
            default:
                return null
        }
    }

    return getContentComponent(content)
}