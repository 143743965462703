import React from 'react';
import PropTypes from "prop-types";

function Document({ data: { title, meta: { download_url } } }) {
    return (
        <>
            Download document <a target="_blank" rel="noopener noreferrer" href={download_url}>{title}</a>
        </>
    );
}

Document.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        meta: PropTypes.shape({
            download_url: PropTypes.string
        })
    })
};
export default Document;