import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, CustomInput } from "reactstrap";
import { inject, observer } from "mobx-react";
import { FormattedMessage, injectIntl } from "react-intl";
import FieldGroup from "../../FieldGroup/FieldGroup";
import Checkbox from "../../Checkbox/Checkbox";
import LimitedTextarea from "../../LimitedTextarea/LimitedTextarea";


class VoteForm extends Component {
    static propTypes = {
        VoteStore: PropTypes.shape({
            vote: PropTypes.object,
            addVote: PropTypes.func,
            updateProperty: PropTypes.func,
            clearVote: PropTypes.func,
            success: PropTypes.bool,
            error: PropTypes.shape({
                text: PropTypes.string,
                name: PropTypes.string,
                email: PropTypes.string,
                zip_code: PropTypes.string,
                privacy: PropTypes.bool
            })
        }),
        intl: PropTypes.shape({
            messages: PropTypes.object
        }),
        closeModal: PropTypes.func,
        cancel_button_text: PropTypes.string,
        submit_button_text: PropTypes.string,
        privacy_statement: PropTypes.object,
        voteOptions: PropTypes.object
    };
    state = {
        btnDisabled: false
    };

    submitForm = async (e) => {
        e.preventDefault();
        this.setState({ btnDisabled: true });
        await this.props.VoteStore.addVote();
        if (this.props.VoteStore.success) {
            this.props.closeModal(true);
            this.setState({ btnDisabled: false });
        }
        else {
            this.setState({ btnDisabled: false });
        }
    };

    componentDidMount() {
        this.props.VoteStore.clearVote();
    }

    render() {
        const {
            closeModal,
            cancel_button_text,
            submit_button_text,
            privacy_statement,
            VoteStore: {
                vote,
                updateProperty,
                error
            },
            voteOptions,
            intl
        } = this.props;

        function onChange(name, value) {
            updateProperty(name, value);
        }

        return <Form onSubmit={this.submitForm}>
            <FormGroup row className="no-gutters vote-form">
                <Col xs={12}>
                    <h2><FormattedMessage id="voteform_title" /></h2>
                </Col>
                <div>
                    {voteOptions.map(voteOption =>
                        <CustomInput onChange={() => onChange("vote", voteOption.id)} className="mb-2" key={voteOption.id} type="radio" id={voteOption.id} name="vote" label={voteOption.caption} />
                    )}
                    <CustomInput onChange={() => onChange("vote", "")} type="radio" id="0" name="vote" label={intl.messages.voteform_noOpinion} />
                </div>
            </FormGroup>

            <LimitedTextarea
                limit={500}
                value={vote.text}
                name="text"
                label={intl.messages.voteform_reaction_label}
                placeholder={intl.messages.voteform_reaction_placeholder}
                onChange={onChange}
                error={error.text}
            />

            <FieldGroup name="name"
                label={intl.messages.voteform_name_label}
                value={vote.name}
                type="text"
                placeholder={intl.messages.voteform_name_placeholder}
                error={error.name}
                onChange={onChange} />

            <FieldGroup name="email"
                label={intl.messages.voteform_email_label}
                value={vote.email}
                type="email"
                error={error.email}
                placeholder={intl.messages.voteform_email_placeholder}
                onChange={onChange} />

            <FieldGroup name="zip_code"
                label={intl.messages.voteform_zipcode_label}
                value={vote.zip_code}
                type="text"
                placeholder={intl.messages.voteform_zipcode_placeholder}
                error={error.zip_code}
                onChange={onChange} />

            <FormGroup tag="fieldset">
                <Checkbox error={error.privacy} value={vote.privacy} onChange={onChange} name="privacy">
                    <FormattedMessage id="voteform_privacystatement_text_prefix" /> <a href={privacy_statement.file} rel="noopener noreferrer"
                        target="_blank"><FormattedMessage id="voteform_privacystatement_text" /></a>
                </Checkbox>
            </FormGroup>


            <Button className="btn-cancel" color="white"
                onClick={() => closeModal(false)}>{cancel_button_text}</Button>
            <Button disabled={this.state.btnDisabled} className="float-right" submit="true" color="primary">{submit_button_text}</Button>
        </Form>
    }
}

export default injectIntl(inject('VoteStore')(observer(VoteForm)));