import React from 'react';
import { PropTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import { Row, Col } from 'reactstrap';
import Blocks from '../Blocks';


function MultiColumnsBlock({ data, disableLazyload }) {


    const column = data.items.map((item, key) =>
        <Col md={item.width.select_width} key={key} className="multi_columns_block_column">
            <Blocks disableLazyload nestedBlock blocks={item.column} />
        </Col>
    )

    return (
        <Row className={"justify-content-center "}>
            {column}
        </Row>
    );
}

MultiColumnsBlock.propTypes = {
    data: MobxPropTypes.observableArrayOf(PropTypes.shape({
        items: PropTypes.object
    })) ,
    disableLazyload: PropTypes.bool
};
export default MultiColumnsBlock;


