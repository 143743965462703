import React, { useState } from "react";
import PropTypes from "prop-types";
import { ZoomControl, ScaleControl } from "react-leaflet";
import MapLayers from "components/MapLayers/MapLayers";
import { coordsToLatLng } from "utils/geoHelpers";
import LayerLegend from "components/MapLayers/LayerLegend/LayerLegend";
import DefaultMap from "./DefaultMap";
import LocateControl from "./LocateControl/LocateControl";
import GeoSearch from "./GeoSearch/GeoSearch";
import BackgroundControl from "./BackgroundControl/BackgroundControl";

const LEVEL_TO_SCALES = {
    "0": 12288000,
    "1": 6144000,
    "2": 3072000,
    "3": 1536000,
    "4": 768000,
    "5": 384000,
    "6": 192000,
    "7": 96000,
    "8": 48000,
    "9": 24000,
    "10": 12000,
    "11": 6000,
    "12": 3000,
    "13": 1500,
    "14": 750,
    "15": 375,
    "16": 188
}

export default function PageDefaultMap({ activeLayers, details, maxBounds, children, ...props }) {
    const [scale, setScale] = useState()

    return (
        <DefaultMap
            className="map"
            center={coordsToLatLng(details.center.coordinates)}
            maxBounds={maxBounds}
            zoomControl={false}
            base_layer={details.base_layer}
            bounds={maxBounds}
            minZoom={details.minzoom ? details.minzoom : 0}
            maxZoom={details.maxzoom ? details.maxzoom : 14}
            tilt={true}
            useFlyTo={true}
            whenReady={(e) => {
                setScale(LEVEL_TO_SCALES[e.target.getZoom()])
            }}
            onZoomEnd={(e) => {
                setScale(LEVEL_TO_SCALES[e.target.getZoom()])
            }}
            style={{"height": "100%"}}
            {...props}
        >
            <ScaleControl position="bottomright" imperial={false} />
            <GeoSearch position="topright" bounds={maxBounds} />
            {activeLayers.length > 1 &&

                <BackgroundControl
                    base_layer={details.base_layer}
                    layer_lightgrey={details.layer_lightgrey}
                    layer_darkgrey={details.layer_darkgrey}
                    layer_street={details.layer_street}
                    layer_topo={details.layer_topo}
                    layer_satellite={details.layer_satellite}
                />
            }

            <LayerLegend scale={scale} position="topleft" />

            <LocateControl position="topright" />
            <ZoomControl position="topright" zoomInText="<span class='fa fa-plus'></span>" zoomOutText="<span class='fa fa-minus'></span>" />
            <MapLayers />

            {children}
        </DefaultMap >
    )

}

PageDefaultMap.propTypes = {
    activeLayers:PropTypes.array,
    details: PropTypes.shape({
        /**
         * Map center
         */
        center: PropTypes.shape({
            /**
             * Coordinates of the map center
             */
            coordinates: PropTypes.object
        }),
        base_layer: PropTypes.string,
        minzoom: PropTypes.number,
        maxzoom: PropTypes.number,
        layer_lightgrey: PropTypes.bool,
        layer_darkgrey: PropTypes.bool,
        layer_street: PropTypes.bool,
        layer_topo: PropTypes.bool,
        layer_satellite: PropTypes.bool,
    }),
    maxBounds: PropTypes.object,
    mapLayers: PropTypes.any,
    legendLayers: PropTypes.object,
    children: PropTypes.node
}