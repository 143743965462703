import React, { Component } from "react"
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router"
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import MerTree from "./MerTree";

class MerRoutes extends Component {
    static propTypes = {
        history: PropTypes.shape({
            listen: PropTypes.func
        }),
        nodes: MobxPropTypes.observableArray,
        MerStore: PropTypes.shape({
            setEffectTree: PropTypes.func,
            setActiveEffect: PropTypes.func,
            clearEffectTree: PropTypes.func
        }),
        baseRoute: PropTypes.string
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(() => this.forceUpdate());
    }
    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        const { nodes, MerStore, baseRoute } = this.props
        function constructRoute(node, parentRoute, parentNode) {
            MerStore.clearEffectTree()

            const route = `${parentRoute}/${node.meta.type !== "mer.IntroNode" ? node.meta.slug : ""}`;
            function createComponent(node, route) {
                switch (node.meta.type) {
                    case 'mer.AlternativeNode':
                        MerStore.setEffectTree(node)
                        MerStore.setActiveEffect(null)
                        break;
                    case 'mer.EffectNode':
                        MerStore.setActiveEffect(node.effect)
                        break;
                    default:
                        break;
                }
                return route.match.isExact && <MerTree nodes={[node]} />;
            }

            return node.meta.type !== "mer.TextNode" && [
                <Route key={node.id} path={route} render={(route) => createComponent(node, route)} />,
                node.children && node.children.map(childNode => constructRoute(childNode, route, node))
            ]
        }

        return nodes.map(node => constructRoute(node, "/" + baseRoute))
    }
}

export default withRouter(inject("MerStore")(observer(MerRoutes)))