import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { Col, Container, Row } from "reactstrap";
import withPage from "../../components/withPage/withPage";
import RespondModal from "../../components/RespondModal/RespondModal";
import Blocks from "../../components/Blocks/Blocks";
import Footer from "../../components/Footer/Footer";
import Enquete from "../../components/Enquete/Enquete";


export class InfoPage extends Component {
    static propTypes = {
        PageStore: PropTypes.shape({
            loadingEnquete: PropTypes.bool,
            enqueteForm: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            }),
            details: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                title: PropTypes.string,
                body: PropTypes.object,
                footer: PropTypes.object,
                respond_form: PropTypes.object
            }),
            getEnqueteFormSubPage: PropTypes.func
        }),
        ResponseStore: PropTypes.shape({
            setResponsePage: PropTypes.func,
            setRespondForm: PropTypes.func
        })
    };

    componentDidMount() {
        this.props.PageStore.getEnqueteFormSubPage(this.props.PageStore.details.id);
    }

    beforeOpen = (next) => {
        this.props.ResponseStore.setResponsePage(
            this.props.PageStore.details.id
        );
        this.props.ResponseStore.setRespondForm(
            this.props.PageStore.details.respond_form
        );
        next();
    }

    render() {

        const { PageStore: { details, loadingEnquete, enqueteForm } } = this.props;

        return (
            details.body &&
            <>
                <main id="main-content">
                    {details.title &&
                        <Row className="no-gutters pt-4">
                            <Col sm={12} className="mb-0 w-100">
                                <Container>
                                    <h1>{details.title}</h1>
                                </Container>
                            </Col>
                        </Row>
                    }


                    <Blocks blocks={details.body} />

                    {details.respond_form &&
                        <div className="text-right info-btn-response w-100 pb-2">
                            <div className="container text-right">
                            <RespondModal
                                {...details.respond_form}
                                beforeOpen={this.beforeOpen}
                                poi={details.id}
                                poi_text={details.title}
                            />
                            </div>
                        </div>
                    }

                    {!loadingEnquete && enqueteForm.id &&
                        <Row className="no-gutters pt-4">
                            <Col sm={12} className="mb-0 w-100">
                                <Container>
                                    <Enquete {...enqueteForm} />
                                </Container>
                            </Col>
                        </Row>
                    }
                </main>

                <Footer
                 className = {details.respond_form !== '' ? 'info-footer' : '' }
                 {...details.footer}
                  />

            </>

        );
    }
}

export default withRouter(withPage(inject('ResponseStore')(observer(InfoPage))))