import React from 'react';
import PropTypes from "prop-types";
import {Row} from 'reactstrap';
import CardBlock from "./Card";


function Cards(content) {

    const Card = content.data.cards.map((listItem, index) =>
        <CardBlock key={index}  layout={content.data.layout} styling={content.data.className} card={listItem}/>
    );

    return (
        <Row className="justify-content-center">
            {Card}
        </Row>
    );
}

Cards.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({

            image_selector: {
                attribution: PropTypes.string,
                caption: PropTypes.string,
                clickable_image: {
                    hyperlink_intern: PropTypes.string
                },
                image:({
                    large: {
                        src: PropTypes.string
                    }
                }),
            },
            layout:PropTypes.string,
            text:PropTypes.string,
            block_theme : {
                select_theme: PropTypes.string
            },
            margin_bottom : {
                select_margin_bottom: PropTypes.string
            }

        })
    })
};
export default Cards;


