import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Loader from "../Loader/Loader";

/**
 * Wraps a page component with the PageStore
 * @param WrappedComponent Node page component
 * @see PageStore
 * @return string & IWrappedComponent<P> WrappedComponent
 */
export default function withPage(WrappedComponent) {
    return inject('PageStore')(observer(class Page extends Component {
        static propTypes = {
            /**
             * The wagtail api id of the page
             */
            id: PropTypes.number.isRequired,
            /**
             * The page store
             */
            PageStore: PropTypes.shape({
                /**
                 * Get page details by id
                 */
                getPageDetails: PropTypes.func,
                /**
                 * Ojbect containing page content
                 */
                details: PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                    title: PropTypes.string,
                    meta: PropTypes.shape({
                        search_description: PropTypes.string
                    })
                }),
                /**
                 * Loading state of the PageStore
                 */
                loading: PropTypes.bool
            }).isRequired
        };

        componentDidMount() {
            if (this.props.id)
                this.props.PageStore.getPageDetails(this.props.id);
        }

        render() {
            const { PageStore: { details, loading }, id } = this.props;
            if (loading || !details.id || details.id !== id) {
                return <Loader />
            } else {
                return <>
                    <Helmet>
                        <title>{details.title}</title>
                        <meta name="description" content={details.meta.search_description} />
                    </Helmet>
                    <WrappedComponent {...this.props} />
                </>;
            }
        }
    }))
}