import React from "react";
import PropTypes from "prop-types";

export default function LegendItem({ layerItem, legendItem }) {
    return <div className={layerItem.legend.length > 1 ? "ml-3" : ""}>
        {legendItem.label === '' ? <strong> {layerItem.layerName} </strong> : null}
        <img
            alt={`${(legendItem.label === '' ? layerItem.layerName : legendItem.label)}`}
            src={`data:${legendItem.contentType};base64,${legendItem.imageData}`}
        />
        {legendItem.label === '' ? null : legendItem.label}
    </div>
}

LegendItem.propTypes = {
    layerItem: PropTypes.shape({
        legend: PropTypes.array,
        layerName: PropTypes.string
    }),
    legendItem: PropTypes.shape({
        label: PropTypes.string,
        contentType: PropTypes.string,
        imageData: PropTypes.string
    })
}