import React, { Component } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { withRouter } from 'react-router';
import PropTypes from "prop-types";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { coordsToLatLng } from "utils/geoHelpers";
import MapIcon from "../MapIcon/MapIcon";
import POIMarker from "../POIMarker/POIMarker";
import ClusterIcon from "../ClusterIcon/ClusterIcon";
/**
 * POI Layer
 *
 * @description
 * Renders pois on the map
 */
class POILayer extends Component {
    static propTypes = {
        /**
         * Page store
         */
        PageStore: PropTypes.shape({
            /**
             * Page details
             */
            details: PropTypes.shape({
                /**
                 * Page id
                 */
                id: PropTypes.number
            }),
            /**
             * Array of pois
             */
            pois: MobxPropTypes.observableArrayOf(PropTypes.shape({
                /**
                 * POI location
                 */
                location: PropTypes.object
            })),
            /**
             * Get pois
             */
            getPois: PropTypes.func,
            /**
             * Loading state
             */
            loading: PropTypes.bool
        }),
        /**
         * React router history
         */
        history: PropTypes.shape({
            push: PropTypes.func
        }),
        /**
         * Parent url
         */
        parentPage: PropTypes.string
    };

    componentDidMount() {
        this.props.PageStore.getPois(this.props.PageStore.details.id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.PageStore.details.id !== prevProps.PageStore.details.id) {
            this.props.PageStore.getPois(this.props.PageStore.details.id);
        }
    }

    openPOI = (slug) => {
        this.props.history.push(`${this.props.parentPage}/${slug}`);
    };

    render() {
        const { PageStore: { loading, pois } } = this.props;

        return <MarkerClusterGroup
        spiderfyOnMaxZoom={true}
        zoomToBoundsOnClick={true}
        animate={true}
        showCoverageOnHover={false}
        iconCreateFunction={(cluster) => (
            MapIcon(<ClusterIcon type="poi" markers={cluster.getAllChildMarkers().map(marker=> marker.options)} content={cluster.getChildCount()}/>)
        )}
    >
        {!loading && (pois.length > 0) && pois.map(({ id, comments, ...poi }) => (
            <POIMarker
                key={id}
                onClick={() => this.openPOI(poi.meta.slug)}
                onKeyDown={(event) => event.originalEvent.key === "Enter" && this.openPOI(poi.meta.slug)}
                geom={coordsToLatLng(poi.location.coordinates)}
                {...poi}
            />
        ))}
        </MarkerClusterGroup>
    }
}

export default withRouter(inject('PageStore')(observer(POILayer)));