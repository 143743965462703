import React from "react";
import PropTypes from "prop-types";

function ResponsiveImage({ image, className, alt, fullWidth, title }) {

    const cssClass = (className) ? className : '';
    return <>
        <p className="h5 m-0">{title}</p>
        <picture className={title && 'has-title'}>

            {/* images within columns don't need larger variations */}

            {fullWidth &&
                <source media="(min-width: 768px)" srcSet={image.large.src + " , " + image.retinaLarge.src + " 2x "} />
            }

            <source srcSet={image.thumbnail.src + " , " + image.retinaThumbnail.src + " 2x "} />
            <img className={"w-100 img-fluid fadeIn " + cssClass} src={image.large.src} title={alt} alt={alt} />
        </picture>
    </>
}

ResponsiveImage.propTypes = {
    image: PropTypes.shape({
        large: PropTypes.object,
        retinaLarge: PropTypes.object,
        thumbnail: PropTypes.object,
        retinaThumbnail: PropTypes.object
    }),
    className: PropTypes.string,
    alt: PropTypes.string,
    fullWidth: PropTypes.bool,
    title: PropTypes.string
}

export default ResponsiveImage;