import React, { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Marker } from "react-leaflet";
import { coordsToLatLng } from "utils/geoHelpers";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import MapIcon from "../MapIcon/MapIcon";
import SVGIcons from "../svgIcons/svgIcons";
import ClusterIcon from "../ClusterIcon/ClusterIcon";


const icons = {
    suggestie: 'bulb',
    zorg: 'bubble',
    vraag: 'questionmark'
}


/**
 * Response layer container component
 *
 * @description
 * Renders the response markers in a markercluster
 */
class ResponseLayer extends Component {
    static propTypes = {
        /**
         * The selected map layer for filtering responses
         */
        selectedLayerId: PropTypes.number,
        /**
         * Response store
         */
        ResponseStore: PropTypes.shape({
            /**
             * Loading state
             */
            loading: PropTypes.bool,
            /**
             * Array of responses
             */
            responses: PropTypes.objectOf(PropTypes.shape({
                /**
                 * Id of the response
                 */
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                /**
                 * Response geographic location
                 */
                location: PropTypes.shape({
                    /**
                     * Coordinates of the response
                     */
                    coordinates: PropTypes.array
                }),
            })),
            /**
             * Responding state
             */
            isResponding: PropTypes.bool,
            /**
             * The selected response
             */
            selectedResponse: PropTypes.shape({
                /**
                 * id of the selected response
                 */
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            }),
            /**
             * Function to load all responses
             */
            getAllResponses: PropTypes.func,
        }),
        history: PropTypes.shape({
            push: PropTypes.func
        }),
        match: PropTypes.shape({
            path: PropTypes.string
        }),
        togglePanel: PropTypes.func
    };

    componentDidMount() {
        this.props.ResponseStore.getAllResponses()
    }

    openComment = (id) => {
        const { ResponseStore: { selectedResponse, isResponding }, history, match,togglePanel } = this.props;
        if (isResponding) return;
        const url = selectedResponse.id !== id ? `/response/${id}` : '';
        if(url === '') {
            togglePanel('default')
        }
        else {
            togglePanel('responses')
        }
        history.push(`${match.path}${url}`);
        document.getElementById(`repsonse_${id}`).scrollIntoView(-100);
    };

    getMarker = (response, active) => {
        if (!response) return;
        return <Marker
            role="button"
            icon={MapIcon(<SVGIcons className={`${response.trusted === true ? 'trusted' : ''} map-response-icon map-response-${response.mood} ${active ? 'active' : ''}`} name={icons[response.mood]} title={`${response.mood} geplaatst bij ${response.location_name || 'onbekende locatie'}.`} width={40} height={40} fill="currentColor" />)}
            onClick={() => this.openComment(response.id)}
            onKeyDown={(event) => event.originalEvent.key === "Enter" && this.openComment(response.id)}
            key={response.id}
            active={active}
            zIndexOffset={active ? 1 : 0}
            position={coordsToLatLng(response.location.coordinates)} />;
    }
    render() {
        const { ResponseStore: { loading, responses, selectedResponse }, selectedLayerId } = this.props;

        return <>
            {this.getMarker(responses.find(response => response.id === selectedResponse.id), true)}
            <MarkerClusterGroup
                spiderfyOnMaxZoom={true}
                zoomToBoundsOnClick={true}
                showCoverageOnHover={true}
                iconCreateFunction={(cluster) => MapIcon(<ClusterIcon content={cluster.getChildCount()} />)}
            >
                {!loading && responses.filter(response => response.layer_id === selectedLayerId && response.poi === null && response.id !== selectedResponse.id).map(response => this.getMarker(response, false))}
            </MarkerClusterGroup>
        </>;
    }


}

export default inject('ResponseStore')(observer(ResponseLayer));