import React, {Component} from "react";
import PropTypes from "prop-types";
import withPage from "../../components/withPage/withPage";
import Blocks from "../../components/Blocks/Blocks";
import Footer from "../../components/Footer/Footer";
import HeroBlock from "../../components/Blocks/Hero/Hero";

export class Home extends Component {
    static propTypes = {
        PageStore: PropTypes.shape({
            details: PropTypes.shape({
                body: PropTypes.object,
                cta_link: PropTypes.object,
                cta_text: PropTypes.string,
                hero_image: PropTypes.object,
                footer: PropTypes.object
            })
        })
    };

    render() {

        const {PageStore: {details}} = this.props;

        return (
            details.body &&
            <>
                <main id="main-content">
                    <HeroBlock {...details}/>
                    <Blocks blocks={details.body}/>
                </main>

                <Footer {...details.footer} />

            </>

        );
    }
}

export default withPage(Home)