import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const ScrollHandler = ({ location, children }) => {
  useEffect(
    () => {
      // get fragment identifier from location hash
      const element = document.getElementById(location.hash.split('?')[0].replace("#", ""));

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth"
          })
        }, 100);
      }
    }, [location, children]);

  return children;
};

ScrollHandler.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired
};

export default withRouter(ScrollHandler);