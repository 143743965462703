/**
 * @description
 * Sorts an array of objects by the parameter sort_order
 *
 * @param a
 * @param b
 * @return {number}
 */
export function sortByOrder(a, b) {
    return a.sort_order - b.sort_order;
}