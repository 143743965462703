import { observable, action, decorate, runInAction } from "mobx"
import api from "services/api";
import { uniqBy } from "lodash";

/**
 * Mobx store to load and control Map layers
 */
export class MapLayerStore {
    /**
     * List of map layers
     * @type {Array}
     */
    mapLayers = [];
    /**
     * Selected map layer
     * @type {Object}
     */
    selectedLayer = {};
    /**
     * @type {Array}
     */
    layersToToggle = []
    /**
     * Boolean to show selected layer
     * @type {Boolean}
     */
    showSelectedLayer = false;
    /**
     * Is legend loading
     * @type {Boolean}
     */
    legendLoading = false;
    /**
     * Error during loading of legend
     * @type {Object}
     */
    legendError = {};
    /**
     * List of legend items
     * @type {Array}
     */
    legendItems = [];

    /**
     * Initializes the mapLayers array and sets the first to active
     * @param {Array} mapLayers
     */
    setMapLayers = (mapLayers) => {
        this.selectedLayer = {};
        return this.mapLayers = mapLayers;
    };

    /**
     * Sets the selected layer
     * @param {object} layer
     */
    setSelectedLayer = (layer) => {
        this.selectedLayer = layer;
        this.setShowSelectedLayer(true);
        this.setLegendOnLayer(layer);
    };

    setLayerToggle = (node) => {
        this.layersToToggle = node.layerlist;
        this.setSelectedLayer(node.layerlist[0])
    }

    clearSelectedLayer = () => {
        this.layersToToggle = [];
        this.selectedLayer = {};
        this.setShowSelectedLayer(false);
    }

    /**
     * Gets the maplayer by id and sets it as selected
     * @param {Number} layerId
     */
    setSelectedLayerByID = (layerId) => {
        if (this.selectedLayer.id === layerId) return;
        this.setSelectedLayer(this.mapLayers.find(layer => layer.id === layerId));
    };

    /**
     * Shows or hides the layer text window
     * @param {Boolean} state
     */
    setShowSelectedLayer = (state) => {
        this.showSelectedLayer = state;
    }

    setLegendOnLayer = async (layer) => {
        if (!layer) return;
        this.legendLoading = true;

        let legend = [];
        let requestsArray = [];
        let mapLayers = [];

        // Check if layer has a layerlist or is a single layer
        if (layer.layerlist) {
            mapLayers = layer.layerlist;

            requestsArray = mapLayers.map(mapLayer => api.Layer.getLegend(mapLayer.value.url, mapLayer.value.token))
        } else {
            if (!layer.showLegend) return;
            mapLayers = [layer]
            requestsArray = [api.Layer.getLegend(layer.url, layer.token)]
        }

        try {
            const values = await Promise.all(requestsArray)

            values.forEach((res, idx) => {
                let legendResult = res.data.layers.filter((index) => this.displayedLayers(index, mapLayers[idx])).map(el => Object.assign(el, { mapLayeridx: idx }));
                legend = [...legend, ...legendResult];
            });
            runInAction(() => {
                layer.legend = uniqBy(legend, function (elem) { return [elem.layerName, elem.maxScale].join() })
                this.selectedLayer = layer;
                this.legendLoading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.legendError = error;
                this.legendLoading = false;
            })
        }
    }

    displayedLayers = (index, mapLayer) => {
        if (mapLayer.layers) {
            return mapLayer.layers.split('|').includes(String(index.layerId));
        } else {
            return true;
        }
    }
}

decorate(MapLayerStore, {
    groups: observable,
    mapLayers: observable,
    selectedLayer: observable,
    layersToToggle: observable,
    selectedToggleLayer: observable,
    showSelectedLayer: observable,
    legendLoading: observable,
    legendError: observable,

    setGroups: action,
    setMapLayers: action,
    setSelectedLayer: action,
    setSelectedToggleLayer: action,
    setLayerToggle: action,
    clearSelectedLayer: action,
    setSelectedLayerByID: action,
    setShowSelectedLayer: action,
    setLegendOnLayer: action
});


export default new MapLayerStore();