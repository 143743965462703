import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { DomEvent } from "leaflet";
import Control from 'react-leaflet-control';
import { injectIntl } from 'react-intl';
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import LegendItem from "./LegendItem";

class LayerLegend extends Component {

    constructor(props) {
        super(props)
        this.state = {
            legendOpen: false,
        };

    }

    static propTypes = {
        MapLayerStore: PropTypes.shape({
            selectedLayer: PropTypes.shape({
                legend: PropTypes.array
            }),
            legendLoading: PropTypes.bool
        }),
        scale: PropTypes.number,
        intl: PropTypes.shape({
            messages: PropTypes.object
        }),
    };
    container = null;

    toggleLegend() {
        this.setState({
            legendOpen: !this.state.legendOpen
        })
    }

    refContainer = (el) => {
        this.container = el;
        if (el) {
            DomEvent
                .disableClickPropagation(this.container)
                .disableScrollPropagation(this.container)
        }
    };

    showOnlyVisibleLegendItems = (layerItem) => {
        return (layerItem.maxScale === 0 || this.props.scale >= layerItem.maxScale) && (layerItem.minScale === 0 || this.props.scale <= layerItem.minScale)
    }

    render() {
        const { MapLayerStore: { selectedLayer, legendLoading } } = this.props;
        return (!legendLoading && selectedLayer.legend && selectedLayer.legend.length) ? <Fragment>
            <Control position="topright">
                <button
                    onClick={(e) => this.toggleLegend()}
                    disabled={(selectedLayer.legend.length > 0 ? null : true)}
                    className={this.state.legendOpen ? 'toggle-legend toggle-legend__active' : 'toggle-legend'}
                    aria-label={this.props.intl.messages.toggle_legend} title={this.props.intl.messages.toggle_legend}
                >
                    <FontAwesomeIcon size="" className=" d-block mx-auto" title={this.props.intl.messages.toggle_legend} icon={faMap} />
                </button>
            </Control>
            {this.state.legendOpen &&
                <Control position="topright">
                    <div
                        id="layer-legend-overlay"
                        className={"legend-layer-overlay " + (selectedLayer.legend.length > 0 ? '' : " legend-layer-overlay-disabled d-none")}
                        ref={this.refContainer}>
                        <div className="legend-layer-overlay-content">
                            {selectedLayer.legend.filter(this.showOnlyVisibleLegendItems).map((layerItem) =>
                                <div key={`map${layerItem.mapLayeridx}_layer${layerItem.layerId}`}>
                                    {/* dont show title if there is a single item in section */}
                                    {layerItem.legend.length > 1 &&
                                        <strong className="py-1 d-block">
                                            {layerItem.layerName}
                                        </strong>
                                    }
                                    {layerItem.legend.map((legendItem, key) => <LegendItem
                                        key={`map${layerItem.mapLayeridx}_layer${layerItem.layerId}_legendItem${key}`}
                                        legendItem={legendItem}
                                        layerItem={layerItem}
                                    />)}
                                </div>
                            )}
                        </div>
                    </div>
                </Control>
            }
        </Fragment> : null;
    }
}

export default inject("MapLayerStore")(injectIntl(observer(LayerLegend)));
