import { observable, action, decorate, runInAction, computed, toJS } from "mobx"
import arrayToTree from "utils/arrayToTree";
import { filterTree, expandFilteredNodes, expandActiveNodes } from "utils/treeFilters";
import api from "services/api";

const EMPTY_ACTIVE_CONTENT = {
    id: null,
    meta: {
        type: ""
    }
};

/**
 * Store which handles the mer node tree
 * @export
 * @class MerStore
 */
export class MerStore {
    /**
     * @type {Boolean}
     * @memberof MerStore
     */
    loading = false;

    /**
     * @type {Object}
     * @memberof MerStore
     */
    nodesTree = {
        items: []
    };

    /**
     * @type {Array}
     * @memberof MerStore
     */
    nodesList = []

    /**
     * @type {Object}
     * @memberof MerStore
     */
    activeContent = EMPTY_ACTIVE_CONTENT;

    /**
     * @type {Object}
     * @memberof MerStore
     */
    effectTree = {};

    /**
     * @type {Object}
     * @memberof MerStore
     */
    activeEffect = {};

    /**
     * @type {string}
     * @memberof MerStore
     */
    filterEffectString = "";

    baseRoute = "/";

    /**
     * Get all nodes from the database
     * @return {Promise<T | never>}
     * @memberof MerStore
     * @param {number} page_id
     */
    getAllNodesAndReturnMapLayers = async (page_id, is_mapMer = false) => {
        this.loading = true;
        try {
            let nodes = await api.Page.getNodes(page_id);
            const mapLayers = nodesToMapLayers(nodes.items);
            runInAction(() => {
                this.loading = false;
                this.nodesList = nodes.items
                is_mapMer && this.setParentText(nodes)
                nodes.items = arrayToTree(nodes.items, {
                    parentProperty: 'meta.parent.id'
                })
                this.nodesTree = nodes
            });

            return mapLayers
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error = error;
            })
        }
    }

    setParentText(nodes) {
        return nodes.items.sort((a, b) => b.id - a.id).map(node => {
            if (!node.use_parent_text)
                return node;
            const parentNode = nodes.items.find(parentNode => parentNode.id === node.meta.parent.id);
            node.text = [...parentNode.text]; // TODO remove before merge to master
            return node;
        });
    }

    /**
     * Get previous node in the list
     *
     * @param {Object} searchNode
     * @returns {Object}
     * @memberof MerStore
     */
    getPreviousNode(searchNode) {
        const previousNodeIndex = this.nodesList.findIndex(node => node.id === searchNode.id) - 1
        if (previousNodeIndex < 0) {
            return searchNode
        } else {
            return this.nodesList[previousNodeIndex]
        }
    }

    /**
     * Sets active content
     * 
     * @param {Object} content
     * @memberof MerStore
     */
    setContent = (content) => {
        this.activeContent = content;
    }

    /**
     * Clears active content to default value
     *
     * @memberof MerStore
     */
    clearContent = () => {
        this.activeContent = EMPTY_ACTIVE_CONTENT;
    }

    get activeEffectTree() {
        if (!this.effectTree.children) {
            return {}
        }
        let activeEffects = toJS(this.effectTree)
        const baseRoute = `/${this.baseRoute}/${activeEffects.meta.slug}`
        activeEffects.url = baseRoute;
        activeEffects.active = !this.activeEffect.id;

        const cleanData = (array, parent = null) => {
            const result = array.filter(node => {
                // Remove non EffectNodes and empty effects
                if (node.meta.type !== 'mer.EffectNode' || !node.effect) return false;

                // set node url
                node.url = (parent ? parent.url : baseRoute) + '/' + node.meta.slug

                // set active effect
                if (this.isActiveNode(this.activeEffect.id, node)) {
                    node.active = true;
                }


                // filter children
                if (node.children) {
                    const effectChildren = cleanData(node.children, node)
                    node.children = effectChildren.length > 0 ? effectChildren : null;
                }

                return true;
            })
            return result;
        }

        activeEffects.children = cleanData(activeEffects.children);

        if (this.filterEffectString) {
            let filtered = filterTree(activeEffects, this.filterEffectString, this.isActiveEffect);
            activeEffects = expandFilteredNodes(filtered, this.filterEffectString, this.isActiveEffect);
        } else {
            activeEffects = expandActiveNodes(activeEffects, this.activeEffect.id, this.isActiveNode)
        }

        return activeEffects;
    }

    /**
     * Checks if current node is active by checking effect id
     * @param {Number} activeId
     * @param {Object} node
     * @returns Boolean
     * @memberof MerStore
     */
    isActiveNode(activeId, node) {
        return node.effect && node.effect.id === activeId
    }

    /**
     * Sets effect tree filter string
     *
     * @memberof MerStore
     */
    filterEffectTree = (filterString) => {
        this.filterEffectString = filterString.trim();
    }

    /**
     * Sets current effect tree
     *
     * @memberof MerStore
     */
    setEffectTree = (node) => {
        if (node.id === this.effectTree.id) return;
        this.effectTree = node;
    }

    clearEffectTree = () => {
        this.effectTree = {}
    }

    setBaseRoute = (baseRoute) => {
        this.baseRoute = baseRoute;
    }

    /**
     * Sets active effect node
     *
     * @memberof MerStore
     */
    setActiveEffect = (effect) => {
        if (effect) {
            this.activeEffect = effect;
        } else {
            this.activeEffect = {}
        }
    }
}

/**
 * Extracts map_content_block properties from all nodes
 * 
 * @param {Array} nodes
 * @returns {Array}
 */
function nodesToMapLayers(nodes) {

    const convertToDefaultLayer = (layer) => {
        const { id, type } = layer;
        return {
            id: id,
            type: type,
            meta: {
                type: 'map.EsriMapLayer'
            },
            ...layer.value
        }
    }

    let mapArray = [];

    nodes.forEach(node => {
        if (!node.map_content_block.length) return;
        const mapContent = node.map_content_block[0];

        if (mapContent.toggle) {
            mapContent.layerlist = mapContent.layerlist.map(layer => {
                const newLayer = convertToDefaultLayer(layer);
                mapArray.push(newLayer)
                return newLayer;
            })
        } else {
            mapArray.push(mapContent);
        }
    });

    return mapArray;
}

decorate(MerStore, {
    loading: observable,
    nodesTree: observable,
    nodesList: observable,
    activeContent: observable,
    effectTree: observable,
    activeEffect: observable,
    filterEffectString: observable,
    baseRoute: observable,

    clearContent: action,
    getAllNodes: action,
    setContent: action,
    setEffectTree: action,
    clearEffectTree: action,
    setActiveEffect: action,
    filterEffectTree: action,
    setBaseRoute: action,

    activeEffectTree: computed
})

export default new MerStore();