import React from "react"
import { PropTypes } from "mobx-react";

function Table({ table }) {
    const tableData = table.table[0].value;

    tableData.table_header = tableData.first_row_is_table_header ? tableData.data[0] : false;

    return <table role="table" border="1" className="w-100 border">
        {tableData.table_caption && <caption>{tableData.table_caption}</caption>}
        {tableData.table_header && <thead>
            <tr>
                {tableData.table_header.map((column, index) => <th key={`table-header-${index}`}>{column}</th>)}
            </tr>
        </thead>}
        <tbody>
            {tableData.data.filter((_, index) => !(tableData.table_header && index === 0)).map((row, index) => <tr key={`table-row-${index}`}>
                {row.map((column, index) => tableData.first_col_is_header ? <th key={`table-header-cell-${index}`}>
                    {column}
                </th> : <td key={`table-cell-${index}`}>
                        {column}
                    </td>
                )}
            </tr>)}
        </tbody >
    </table >
}

Table.propTypes = {
    table: PropTypes.observableObject
}

export default Table;