import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader/Loader';

function PrivateRoute({ userStore, path, ...restProps }) {
    if (userStore.currentUser) return <Route {...restProps} />;
    if (userStore.loadingUser) return <Loader />;
    return <Redirect
        to={{
            pathname: "/login",
            state: { from: path }
        }}
    />;
}

PrivateRoute.propTypes = {
    userStore: PropTypes.shape({
        currentUser: MobxPropTypes.observableObject,
        loadingUser: PropTypes.bool
    }),
    path: PropTypes.string
}

export default inject('userStore')(observer(PrivateRoute))