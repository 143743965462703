import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import FieldGroup from "../FieldGroup/FieldGroup";

class LoginForm extends Component {
    static propTypes = {
        authStore: PropTypes.shape({
            login: PropTypes.func,
            clear: PropTypes.func,
            success: PropTypes.bool,
            values: MobxPropTypes.observableObject,
            updateProperty: PropTypes.func,
            error: PropTypes.shape({
                username: PropTypes.object,
                password: PropTypes.object,
                non_field_errors: PropTypes.object
            })
        }),
        intl: PropTypes.shape({
            messages: PropTypes.object
        }),
        userStore: PropTypes.shape({
            pullUser: PropTypes.func
        }),
        onFinished: PropTypes.func
    };
    state = {
        btnDisabled: false
    };

    submitForm = async (e) => {
        e.preventDefault();
        this.setState({ btnDisabled: true });

        await this.props.authStore.login();

        if (this.props.authStore.success) {
            await this.props.userStore.pullUser();
            this.setState({ btnDisabled: false });
            this.props.onFinished(true);
        }
        else {
            this.setState({ btnDisabled: false });
        }
    };

    componentDidMount() {
        this.props.authStore.clear();
    }

    render() {
        const {
            authStore: {
                values,
                updateProperty,
                error
            }
        } = this.props;

        function onChange(name, value) {
            updateProperty(name, value);
        }

        return (
            <Form onSubmit={this.submitForm}>
                <FieldGroup name="username" label={this.props.intl.messages.loginform_username_label} value={values.username} type="text" placeholder={this.props.intl.messages.loginform_username_placeholder}
                    error={error.username}
                    onChange={onChange} />

                <FieldGroup name="password" label={this.props.intl.messages.loginform_password_label} value={values.password} type="password"
                    error={error.password}
                    placeholder={this.props.intl.messages.loginform_password_placeholder} onChange={onChange} />

                {error.non_field_errors && <p className="text-danger">{error.non_field_errors[0]}</p>}

                <Button disabled={this.state.btnDisabled} submit="true" color="primary" className="d-flex ml-auto"><FormattedMessage id="loginform_button_text" /></Button>
            </Form>
        )
    }
}

export default inject('authStore', 'userStore')(observer(injectIntl(LoginForm)));
