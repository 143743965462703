import React, { Fragment } from 'react'
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import LoginPage from 'scenes/LoginPage/LoginPage';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { getPageComponentFromType } from "../stores/Page/pageUtils";
import Loader from "../components/Loader/Loader";
import NotFound from "../components/NotFound/NotFound";
import UnsubscribePage from '../scenes/UnsubscribePage/UnsubscribePage';
import SearchPage from '../scenes/SearchPage/SearchPage';



/**
 * Routes container component
 *
 * @description
 * Renders the routes from wagtail routes array and child components like a header
 */
const Routes = ({ routes, loading, children, getPathWithParent }) => {
    function constructRoute(route) {
        const routeProps = {
            key: route.id,
            exact: route.meta.type === 'home.HomePage',
            path: getPathWithParent(route),
            render: () => getPageComponentFromType(route)
        }
        if (route.meta.has_restrictions) {
            return <PrivateRoute {...routeProps} />;
        } else {
            return <Route {...routeProps} />;
        }
    }

    return (
        <Fragment>
            {children}
            <Switch>
                <Route path="/unsubscribe/:token" component={UnsubscribePage} />
                {routes.filter(route => route.meta.type !== "pages.MenuGroup").sort((a, b) => b.id - a.id).map(constructRoute)}
                <Route path="/login" component={LoginPage} />
                {loading && <Route path="*" component={Loader} />}
                <Route path="/unsubscribe/:token" component={UnsubscribePage} />
                <Route path="/search/:search?" component={SearchPage} />
                {!loading && <Route path="*" component={NotFound} />}
            </Switch>
        </Fragment>
    )
};

Routes.propTypes = {
    /**
     * wagtail routes array
     */
    routes: MobxPropTypes.observableArrayOf(PropTypes.shape({
        /**
         * unique id of the route
         */
        id: PropTypes.number,
        /**
         * metadata of the route
         */
        meta: PropTypes.shape({
            /**
             * Route type
             */
            type: PropTypes.string,
            /**
             * Route slug
             */
            slug: PropTypes.string
        })
    })),
    /**
     * PageStore loading state
     */
    loading: PropTypes.bool,
    /**
     * Header component
     */
    children: PropTypes.node,
    /**
     * Constructs path from page with parent route
     */
    getPathWithParent: PropTypes.func
};

export default Routes