import React from 'react';
import {PropTypes} from "mobx-react";
import CollapseItem from "../../components/CollapseList/CollapseItem";

function CollapseList({list}) {

    const collapseItem = list.map((listItem, index) =>
        <li key={index} className="panel panel-default mb-0">
            <CollapseItem answer={listItem.answer} question={listItem.question}/>
        </li>
    );

    return (<ul className="list-unstyled">
            {collapseItem}
        </ul>
    );
}

CollapseList.propTypes = {
    list: PropTypes.observableArray
};

export default CollapseList;