import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";

function Image(content) {

    let hyperlink = content.data.image_selector.clickable_image.hyperlink_intern;


    return (
        <>
            {hyperlink ? (
                <Link className="d-block" to={hyperlink.meta.slug}>
                    <ResponsiveImage 
                        alt ={content.data.image_selector.attribution}
                        image={content.data.image_selector.image}
                        fullWidth={true}
                    />
                </Link>
            ):(
                <ResponsiveImage 
                    alt ={content.data.image_selector.attribution}
                    image={content.data.image_selector.image}
                    fullWidth={true}
                />
            )}
        </>
    );
}

Image.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({

            image_selector: {
                attribution: PropTypes.string,
                caption: PropTypes.string,
                clickable_image: {
                    hyperlink_intern: PropTypes.string
                },
                image:({
                    large: {
                        src: PropTypes.string
                    }
                }),
            },
            block_theme : {
                select_theme: PropTypes.string
            },
            margin_bottom : {
                select_margin_bottom: PropTypes.string
            },
            text_alignment : {
                select_text_alignment: PropTypes.string
            }

        })
    })
};
export default Image;