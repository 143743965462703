import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "reactstrap";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";

function SearchForm({ isOpen, searchString, onSearch, history, intl }) {
    const [search, changeSearch] = useState(searchString)
    function submitForm(submitEvent) {
        submitEvent.preventDefault();
        history.push('/search/' + search)
        onSearch(search)
    };

    return (
        <div id="site-search" aria-hidden={!isOpen} className={isOpen ? 'open' : ''}>
            {isOpen &&
                <form onSubmit={submitForm}>
                    <Container >
                        <Row>
                            <Col xs="12">
                                <label htmlFor="searchfield">Doorzoek de site</label>
                            </Col>
                            <Col className="flex-grow-1 mb-0">
                                <input
                                    id="searchfield"
                                    ref={input => isOpen && input && input.focus()}
                                    autoFocus
                                    type="search"
                                    className="form-control"
                                    placeholder={intl.messages.search_placeholderText}
                                    value={search} onChange={(e) => changeSearch(e.target.value)}
                                />
                            </Col>
                            <Col className="col-auto site-search-button">
                                <button type="submit" className="btn btn-primary"><FormattedMessage id="search_buttonText" /></button>
                            </Col>
                        </Row>
                    </Container>
                </form>
            }
        </div>
    )
}

SearchForm.propTypes = {
    isOpen: PropTypes.bool,
    searchString: PropTypes.string,
    onSearch: PropTypes.func,
    intl: PropTypes.shape({
        messages: PropTypes.object
    }),
    history: PropTypes.shape({
        push: PropTypes.func
    })
}

export default injectIntl(withRouter(SearchForm));