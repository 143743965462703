import React from 'react';
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { Row, Col } from 'reactstrap';
import Blocks from '../Blocks';


function TwoColumns({ data, disableLazyload }) {
    const columns = [];
    let classes = "";
    data.map(item => {
        switch (item.type) {
            case "columns":
                return columns.push(getColumnElements(item.value))
            case "block_theme":
                return classes += `theme-${item.value.select_theme} `
            case "margin_bottom":
                return classes += `${item.value.select_margin_bottom} `
            default:
                return null
        }
    })

    function getColumnElements(props) {
        return Object.keys(props).map(key => {
            const column = props[key];
            return <Col md={6} key={key} className="two_columns_block_column">
                <Blocks disableLazyload nestedBlock blocks={column} />
            </Col>
        });
    }

    return (
        <Row className={"justify-content-center " + classes}>
            {columns}
        </Row>
    );
}

TwoColumns.propTypes = {
    data: MobxPropTypes.observableArrayOf(PropTypes.shape({
        type: PropTypes.oneOf(["columns", "block_theme", "margin_bottom"]),
        value: PropTypes.object
    })),
    disableLazyload: PropTypes.bool
};
export default TwoColumns;


