import React from 'react';
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

function CallToAction({ PageStore: { getPathWithParent }, ...content }) {

    let buttonTheme = "";
    if (content.data.button_theme.select_theme) {
        buttonTheme = "btn-" + content.data.button_theme.select_theme;
    }

    let hyperlink = content.data.button_hyperlink.hyperlink_intern;


    return (
        <Link className={buttonTheme + " " + (content.data.button_size ? content.data.button_size : "") + " btn"}
            to={getPathWithParent(hyperlink)}>{content.data.text}</Link>
    );
}

CallToAction.propTypes = {
    PageStore: PropTypes.shape({
        getPathWithParent: PropTypes.func
    }),
    content: PropTypes.shape({
        data: PropTypes.shape({
            text: PropTypes.string,
            block_theme: PropTypes.oneOf(['theme-primary', 'theme-secondary', 'theme-white', 'theme-light', 'theme-dark']),
            button_hyperlink: {
                hyperlink_intern: PropTypes.string
            },
            button_theme: PropTypes.shape({
                select_theme: PropTypes.string
            }),
            button_size: PropTypes.string,
            margin_bottom: {
                select_margin_bottom: PropTypes.string
            },
            text_alignment: {
                select_text_alignment: PropTypes.string
            }
        })
    })
};
export default inject("PageStore")(observer(CallToAction));