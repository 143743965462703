import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { FormattedMessage } from "react-intl";
import ResponsiveImage from "components/ResponsiveImage/ResponsiveImage";
import Table from "components/Blocks/Table/Table"
import WayPointText from "./WayPointText";
import ScrollHandler from "./ScrollHandler";

/**
 * Returns Mer text and dynamic content based on mobile or desktop
 *
 * @export
 */
export default function MerText({ nodes, togglePanel, parentSlug = "", ...props }) {
    return <ScrollHandler>{nodes.map((node) => {
        let children = null;

        const element = <WayPointText node={node} {...props} />

        if (node.children) {
            children = <MerText nodes={node.children.filter(node => node.meta.type === "mer.TextNode")} parentSlug={node.meta.slug + "-"} {...props} />
        }

        const imageIterator = node.image_content_block.map((image, index) => {
            return <ResponsiveImage key={index} image={image} title={image.title} />
        });

        const tableIterator = node.table_content_block.map((table, index) => {
            return <Table key={index} table={table} />
        });

        return <Fragment key={node.id}>
            <div className="map-mer-text-chapter pb-2" key={node.id} id={`${parentSlug}${node.meta.slug}`}>
                {node.name && <h2 className="map-mer-head pt-2 h4">{node.name}</h2>}
                {element}
                <div name={`text${node.id}`} />

                {node.image_content_block.length > 0 &&
                    <div className="mb-4 map-mer-chapter-image">
                        {imageIterator}
                    </div>
                }
                {node.table_content_block.length > 0 &&
                    <div className="mb-4  map-mer-chapter-table">
                        {tableIterator}
                    </div>
                }
                {node.map_content_block.length > 0 &&
                    <button onClick={(e) => togglePanel('default')} className="btn btn-primary btn-sm btn-togglemap" ><FormattedMessage id="mertext_map_text" /></button>
                }

                {children}
            </div>
        </Fragment>
    })}
    </ScrollHandler>
}

MerText.propTypes = {
    /**
     * Mer text nodes
     */
    nodes: PropTypes.oneOfType([MobxPropTypes.observableArray, PropTypes.array]),
    togglePanel: PropTypes.func,
    parentSlug: PropTypes.string
}