import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";

function ResponseItem({ ResponseStore: { selectedResponse, isResponding }, history, match, id, name, client_name, client_response, involvement, location_name, mood, text, selected, togglePanel, trusted }) {
    function openComment(id) {
        //on a poi-page, you shouldn't have to click on a comment
        if (isResponding) return;
        const url = selectedResponse.id !== id ? `/response/${id}` : '';
        if(url === '') {
            togglePanel('default')
        }
        history.push(`${match.path}${url}`);

    };

    return (
        <>
            {id ?
                <button disabled={!togglePanel} onClick={() => openComment(id)} className={`map-response-item ` + (selected ? ` map-response-item-selected` : '')} id={`repsonse_${id}`} aria-label={`${mood} geplaatst bij ${location_name || 'onbekende locatie'}.`} data-selected={selected}>

                    <span className="map-response-item-title">
                        <span className="map-response-item-title-name">{name}</span>
                        <span className="map-response-item-title-role">{involvement.name && " (" + involvement.name + ")"}</span>
                        {(location_name && !trusted) && <span className="map-response-item-title-location">{location_name}</span>}
                    </span>
                    {
                        trusted === true ? <blockquote className="map-response-item-blockquote"><FormattedMessage id="responseitem_trusted_text" /></blockquote> : <blockquote className="map-response-item-blockquote">{text}</blockquote>
                    }
                    {client_response && !trusted &&
                        <React.Fragment>
                            <span className="map-response-item-title" aria-label={location_name}>
                                <span className="map-response-item-title-name">{client_name}</span>
                            </span>
                            <blockquote className="map-response-item-blockquote" dangerouslySetInnerHTML={{ __html: client_response }} />
                        </React.Fragment>
                    }
                </button>

                : "Deze reactie is verwijderd of bestaat niet"}

        </>
    )

}

ResponseItem.propTypes = {
    id: PropTypes.number,
    client_name: PropTypes.string,
    name: PropTypes.string,
    involvement: PropTypes.shape({
        name: PropTypes.string
    }),
    location_name: PropTypes.string,
    togglePanel: PropTypes.func,
    mood: PropTypes.string,
    text: PropTypes.string,
    client_response: PropTypes.string,
    selected: PropTypes.bool,
    ResponseStore: PropTypes.shape({
        isResponding: PropTypes.bool,
        selectedResponse: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),

    }),
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    match: PropTypes.shape({
        path: PropTypes.string
    })
}


export default inject('ResponseStore')(observer(ResponseItem));