import React, { Component } from 'react';
import { LatLngBounds } from 'leaflet';
import PropTypes from "prop-types";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { coordsToLatLng } from "utils/geoHelpers";
import withPage from "components/withPage/withPage";
import PageDefaultMap from "components/DefaultMap/PageDefaultMap";
import IntroTour from "components/IntroTour/IntroTour";
import MerTree from 'components/Mer/MerTree';
import MerContent from 'components/Mer/MerContent';
import { Button, ButtonGroup } from 'reactstrap';
import { faInfoCircle, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MerRoutes from 'components/Mer/MerRoutes';
import EffectMenu from 'components/Mer/EffectMenu/EffectMenu';
import slugify from 'slugify';
import FeatureLayer from 'components/MapLayers/FeatureLayer/FeatureLayer';
import { withRouter } from 'react-router';
import MapLayerContainer from 'components/MapLayers/MapLayerContainer/MapLayerContainer';



/**
 * Mer Page
 *
 * @description
 * Renders the MER page from wagtail including all components
 */
class MerPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disableMapElements: false,
            panelOpen: '' //information, layers, responses
        };

        this.toggleMapElements = this.toggleMapElements.bind(this)
        this.togglePanel = this.togglePanel.bind(this)
    }

    toggleMapElements(attribute) {
        this.setState({
            disableMapElements: attribute
        })
    }


    togglePanel(e) {
        if (this.props.match.path) {
            const url = '';
            this.props.history.push(`${this.props.match.path}${url}`);
        }

        // toggle value
        this.setState({ panelOpen: (e === this.state.panelOpen) ? '' : e })
    }


    static propTypes = {
        id: PropTypes.number,
        /**
         * React router match prop
         */
        match: PropTypes.object,
        /**
         * React router history prop
         */
        history: PropTypes.object,
        location: PropTypes.object,
        meta: PropTypes.shape({
            type: PropTypes.string,
        }),
        /**
         * Page Store
         */
        PageStore: PropTypes.shape({
            /**
             * Map Page details
             */
            details: PropTypes.shape({
                toggle_zoom: PropTypes.func,
                introduction: MobxPropTypes.observableObject,
                introduction_button: PropTypes.string,
                autoplay: PropTypes.bool,
                visibility_maplayers: PropTypes.bool,
                visibility_options: PropTypes.bool,
                visibility_legend: PropTypes.bool,
                /**
                 * Map center
                 */
                upper_left: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                lower_right: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                meta: PropTypes.shape({
                    slug: PropTypes.string,
                    type: PropTypes.string
                }),
                feature_layer: PropTypes.object
            })
        }),
        MerStore: PropTypes.shape({
            setBaseRoute: PropTypes.func,
            getAllNodes: PropTypes.func,
            getAllNodesAndReturnMapLayers: PropTypes.func,
            nodesTree: MobxPropTypes.observableObject,
            activeContent: MobxPropTypes.observableObject,
            activeEffectTree: PropTypes.object
        }),
        MapLayerStore: PropTypes.shape({
            mapLayers: MobxPropTypes.observableArray,
            layersToToggle: MobxPropTypes.observableArray,
            selectedLayer: MobxPropTypes.observableObject,
            setMapLayers: PropTypes.func,
            setSelectedLayer: PropTypes.func
        })
    };

    state = {
        disableMapElements: false,
        featureLink: ""
    };

    timer;

    toggleMapElements = (attribute) => {
        this.setState({
            disableMapElements: attribute
        })
    }


    componentDidMount() {
        const { id, MapLayerStore: { setMapLayers }, MerStore: { getAllNodesAndReturnMapLayers, setBaseRoute } } = this.props;
        getAllNodesAndReturnMapLayers(id, this.props.PageStore.details.meta.type === 'mer.MapMerPage').then(mapLayers => {
            mapLayers && setMapLayers(mapLayers);
        });
        setBaseRoute(this.props.PageStore.details.meta.slug)
    }

    onFeatureClick = (feature) => {
        const { PageStore: { details }, history, location } = this.props;

        const featureSlug = slugify(feature.properties[details.feature_layer[0].interactive_column], { lower: true });
        if (location.pathname.match(new RegExp(`/\/${featureSlug}/`, 'g'))) return;

        const link = `/${details.meta.slug}/${featureSlug}`;
        this.setState({ featureLink: link })
        // Wait for popup to open, else continue link
        this.timer = setTimeout(function () { history.push(link) }, 500);
    }

    disableFeatureClick = () => {
        // if popup is opened cancel the route change
        clearTimeout(this.timer)
    }

    render() {
        const { PageStore: { details }, MerStore: { nodesTree, activeContent, activeEffectTree }, MapLayerStore: { setSelectedLayer, mapLayers, selectedLayer, layersToToggle } } = this.props;

        const maxBounds = new LatLngBounds(coordsToLatLng(details.upper_left.coordinates), coordsToLatLng(details.lower_right.coordinates));

        let legendlayers = [];
        if (mapLayers.length && selectedLayer.meta && selectedLayer.meta.type === 'mer.MapContentBlock') {
            legendlayers = selectedLayer.layerlist.filter(el => el.value.showLegend).map(layer => layer.value);
        }

        return (
            <React.Fragment>
                <main id="main-content" className={" map-page map-mer-page " + (this.state.disableMapElements ? " map-response-active " : "")} data-open={this.state.panelOpen ? this.state.panelOpen : 'default'}>
                    <div className="wb-map__column">
                        {details.meta.type === 'mer.ReportMerPage' ? <MerTree togglePanel={this.togglePanel} nodes={nodesTree.items} /> : <MerRoutes baseRoute={details.meta.slug} nodes={nodesTree.items} />}
                    </div>

                    <div aria-live="polite">
                        <MerContent content={activeContent} />

                        <PageDefaultMap className="map"
                            details={details}
                            maxBounds={maxBounds}
                            mapLayers={legendlayers}
                            selectedLayer={selectedLayer}
                            onPopupOpen={this.disableFeatureClick}
                        >
                            {activeEffectTree.id && <EffectMenu baseRoute={details.meta.slug} node={activeEffectTree} />}

                            <MapLayerContainer
                                togglePanel={this.togglePanel}
                                toggleZoom={details.toggle_zoom}
                                mapLayers={layersToToggle}
                                selectedLayer={selectedLayer}
                                maxBounds={maxBounds}
                                setSelectedLayer={setSelectedLayer} />

                            {details.introduction &&

                                <IntroTour
                                    button_text={details.introduction_button}
                                    autoplay={details.autoplay}
                                    {...details.introduction}
                                />
                            }
                            {details.feature_layer && details.feature_layer.length > 0 && <FeatureLayer pane="tilePane" layer={details.feature_layer[0]} onFeatureClick={this.onFeatureClick} />}
                        </PageDefaultMap>
                    </div>
                </main>
                <div className='wb-map__footer'>
                    <ButtonGroup>
                        <Button className='border-0' outline={this.state.panelOpen !== 'information'} onClick={(e) => this.togglePanel('information')}>
                            <FontAwesomeIcon title="Information" icon={faInfoCircle} />
                            Informatie
                        </Button>
                        <Button className='border-0' outline={this.state.panelOpen !== 'layers'} onClick={(e) => this.togglePanel('layers')}>
                            <FontAwesomeIcon title="Maplayers" icon={faLayerGroup} />
                            Kaartlagen
                        </Button>
                    </ButtonGroup>
                </div></React.Fragment>
        );
    }
}

export default withRouter(withPage(inject('PageStore', 'MerStore', 'MapLayerStore')(observer(MerPage))));