import React from "react";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Button, Container } from "reactstrap";
import Blocks from "../../Blocks/Blocks";

function LayerText({ MapLayerStore: { selectedLayer, setShowSelectedLayer },  togglePanel }) {
    return (selectedLayer.text && selectedLayer.text.length) ? (
        <div className="map-text theme-white">
               <div className="text-right map-text__readless">
                <Container>
                    <Button size="sm" onClick={e => { setShowSelectedLayer(false); togglePanel('default') }}><FormattedMessage id="layer_closebutton_text" /></Button>
                </Container>
            </div>
            <Blocks blocks={selectedLayer.text} />
            <div className="map-text__readmore">
                <Container>
                    <Button onClick={(e) => togglePanel('information')} size="sm"><FormattedMessage id="layer_expandbutton_text" /></Button>
                </Container>
            </div>
        </div>
    )
        : null;
}

LayerText.propTypes = {
    intl: PropTypes.shape({
        messages: PropTypes.shape({
            layer_closebutton_text: PropTypes.string
        })
    }),
    MapLayerStore: PropTypes.shape({
        mapLayers: MobxPropTypes.observableArray,
        selectedLayer: MobxPropTypes.observableObject,
        showSelectedLayer: PropTypes.bool,
        setShowSelectedLayer: PropTypes.func
    }),
    togglePanel: PropTypes.func
};

export default inject('MapLayerStore')(observer(LayerText));