import React from 'react';
import PropTypes from "prop-types";
import { Row, Container } from 'reactstrap';
import { config } from 'utils/envHelpers';
import LazyLoad from 'react-lazyload';
import FooterLinks from './Footerlinks';
import FooterSocialmedia from './Footersocialmedia';

function Footer({ id, body, className, ...props }) {
    return (
        <footer className={`footer ${className} ${config.footer.cssClasses}`}>
            <Container>
                <LazyLoad offset={100}>
                    <Row>
                        {id &&
                            <div className="footer-text col-12" dangerouslySetInnerHTML={{ __html: body }} />
                        }

                        <FooterLinks {...props} />

                        <FooterSocialmedia {...props} />

                    </Row>
                </LazyLoad>
            </Container>
        </footer>
    );
}

Footer.propTypes = {
    id: PropTypes.number,
    body: PropTypes.string,
    className: PropTypes.string
};


export default Footer;