import React from "react";
import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label, FormText } from "reactstrap";


function FieldGroup({ label, name, children, onChange, error, help_text, placeholder, ...inputProps }) {
    if (onChange) inputProps.onChange = (e) => onChange(e.target.name, e.target.value)
    return <FormGroup>
        <Label for={name}>{label}</Label>
        <Input invalid={!!error} name={name} id={name}
            placeholder={placeholder}
            {...inputProps}>{children}</Input>
        {error && <FormFeedback>{error[0]}</FormFeedback>}
        {help_text && <FormText>{help_text}</FormText>}
    </FormGroup>
}

FieldGroup.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    error: PropTypes.object,
    help_text: PropTypes.string,
    placeholder: PropTypes.string
}

export default FieldGroup;