import { observable, action, decorate, runInAction } from 'mobx';
import api from 'services/api';

export class UserStore {

    currentUser;
    loadingUser;
    error;


    pullUser = async () => {
        this.loadingUser = true;
        try {
            let response = await api.Auth.current();

            runInAction(() => {
                this.loadingUser = false;
                this.currentUser = response;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingUser = false;
                this.error = error.response.data;
            })
        }
    }

    forgetUser() {
        this.currentUser = undefined;
    }

}

decorate(UserStore, {
    currentUser: observable,
    loadingUser: observable,
    error: observable,
    pullUser: action,
    forgetUser: action
})

export default new UserStore()
