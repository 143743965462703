import React from 'react';
import PropTypes from "prop-types";
import { Button } from "reactstrap";

export function LayerSelectorButton({ item, selectedItem, setSelectedItem }) {
    return <Button className="btn-sm mr-1" outline onClick={() => setSelectedItem(item)} active={item === selectedItem}>
        {item.name || item.title}
    </Button>;
}

LayerSelectorButton.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string
    }),
    selectedItem: PropTypes.object,
    setSelectedItem: PropTypes.func
}