import React, { useEffect, useState } from 'react';
import Control from 'react-leaflet-control';
import { withLeaflet } from "react-leaflet";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import RespondModal from "../RespondModal/RespondModal";

/**
 * Respond overlay component to handle the responding interactions on the map
 */
function RespondOverlay(
    {
        link,
        base_layer,
        respond_form,
        leaflet: { map },
        ResponseStore: {
            setResponsePosition,
            setResponseLayer,
            setRespondForm,
            response: { position }
        },
        selectedLayer,
        toggleMapElements
    }) {


    const [open, setOpen] = useState(false);

    useEffect(() => {
        map.on('click', handleMapClick);

        return () => {
            map.off('click', handleMapClick)
        }
    }, []);

    const handleMapClick = (clickEvent) => {
        toggleMapElements(true);
        setResponsePosition(clickEvent.latlng);
        setRespondForm(respond_form)
        setResponseLayer(selectedLayer);
        setOpen(true)
    };


    const stopResponding = () => {
        toggleMapElements(false);
        setResponsePosition(null);
        setOpen(false);
    };

    setResponseLayer(selectedLayer);

    return <Control>
        <RespondModal
            key={open}
            initializeOpen={open}
            beforeClose={stopResponding}
            buttonVisible={false}
            {...respond_form}
            center={position}
            base_layer={base_layer}
            link={link}
        /></Control>
}

RespondOverlay.propTypes = {
    link: PropTypes.string,
    base_layer: PropTypes.string,
    respond_form: PropTypes.object,
    toggleMapElements: PropTypes.func,
    leaflet: PropTypes.shape({
        map: PropTypes.object
    }),
    ResponseStore: PropTypes.shape({
        isResponding: PropTypes.bool,
        setResponseLayer: PropTypes.func,
        clearResponse: PropTypes.func,
        setRespondForm: PropTypes.func,
        setResponsePosition: PropTypes.func,
        toggleResponding: PropTypes.func,
        response: PropTypes.shape({
            position: PropTypes.object
        })
    }),
    selectedLayer: PropTypes.object
};

export default withLeaflet(inject("ResponseStore")(observer(RespondOverlay)));