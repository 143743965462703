import React from "react";
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom";
import { withLeaflet } from "react-leaflet";

function EffectButton({ node, leaflet }) {
    return (
        <NavLink onClick={() => leaflet.map.closePopup()} className="d-flex flex-fill justify-content-between mer-effect-submenu" to={node.url} >
            <span className={`d-inline-flex text-left mer-effect-submenu-label  ${node.active && 'font-weight-bold'}`}>{node.effect.name}</span>
            <img alt={node.effect.name} src={node.effect.icon.url} />
        </NavLink>
    );
};

EffectButton.propTypes = {
    node: PropTypes.shape({
        active: PropTypes.bool,
        effect: PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.shape({
                url: PropTypes.string
            })
        }),
        name: PropTypes.string,
        icon: PropTypes.shape({
            url: PropTypes.string
        }),
        url: PropTypes.string
    }),
    leaflet: PropTypes.shape({
        map: PropTypes.shape({
            closePopup: PropTypes.func
        })
    })
}

export default withLeaflet(EffectButton);