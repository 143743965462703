import React from 'react'
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import BasicBlock from "../../components/Blocks/Basic/Basic";


export default function Blocks({ blocks, disableLazyload, nestedBlock }) {
    const blockIterator = blocks.map((block) => {
        return <BasicBlock disableLazyload={disableLazyload} nestedBlock={nestedBlock} key={block.id} type={block.type} data={block.value} />
    });

    return (
        <>
            {blockIterator}
        </>
    );
}

Blocks.propTypes = {
    blocks: MobxPropTypes.observableArrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            type: PropTypes.string
        })
    ),
    disableLazyload: PropTypes.bool,
    nestedBlock: PropTypes.bool
};