import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage  } from "react-intl";
import ResponseItem from "./ResponseItem";

// TODO implement response list
function ResponseList({ responses }) {

    const responseItem = responses.map((responseItem) =>
        <ResponseItem key={responseItem.id} {...responseItem} />
    );

    return (
        <div className="pt-4">
            <h2><FormattedMessage id="responselist_title" /></h2>
            {responseItem}
        </div>
    )

}

ResponseList.propTypes = {
    responses: PropTypes.array
}

export default ResponseList;