import { observable, action, runInAction, decorate } from 'mobx';
import UserStore from 'stores/User/UserStore';
import commonStore from 'stores/Common/CommonStore';
import api from 'services/api';

export class AuthStore {
    loading = false;
    error = {};
    success = false;

    values = {
        username: '',
        password: '',
    };

    updateProperty = (key, value) => {
        this.values[key] = value;
    };

    clear() {
        this.values.username = '';
        this.values.password = '';
    }

    login = async () => {
        this.loading = true;
        this.success = false;

        try {
            let response = await api.Auth.login(this.values)

            runInAction(async () => {
                this.loading = false;
                commonStore.setToken(response.token)
                this.success = true;
            })
        } catch (error) {

            runInAction(() => {
                this.loading = false;
                this.error = error.response.data;
            })
        }
    }

    logout() {
        commonStore.setToken(undefined);
        UserStore.forgetUser();
        return Promise.resolve();
    }
}

decorate(AuthStore, {
    loading: observable,
    error: observable,
    success: observable,
    values: observable,
    clear: action,
    updateProperty: action,
    login: action,
    logout: action
})

export default new AuthStore();
