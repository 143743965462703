import React, { Component } from "react";
import { Button, Form, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import serialize from "form-serialize";
import SimpleModal from "../../SimpleModal/SimpleModal";
import EnqueteFormField from "../EnqueteFormField/EnqueteFormField";
import ConditionCheckBox from "../ConditionCheckBox/ConditionCheckBox";

/**
 * @class EnqueteForm
 * @extends {Component}
 * Shows the enqueteform and tracks if the user has not filled in the form
 */
class EnqueteForm extends Component {
    static propTypes = {
        /**
         * Enquete id
         */
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        /**
         * Enquete form fields
         */
        form_fields: MobxPropTypes.observableArray,
        /**
         * Submit button text
         */
        submit_button_text: PropTypes.string,
        /**
         * Text if form was submitted
         */
        submitted_text: PropTypes.string,
        /**
         * Enquete store
         */
        EnqueteStore: PropTypes.shape({
            /**
             * Add submission 
             */
            addSubmission: PropTypes.func,
            success: PropTypes.bool
        }),
        /**
         * Enquete condition
         */
        conditions: PropTypes.object,
        /**
         * Success modal title
         */
        thank_you_title: PropTypes.string,
        /**
         * Success modal text
         */
        thank_you_text: PropTypes.string
    };

    state = {
        open: false,
        btnDisabled: false
    };

    openModal = () => {
        return this.setState({ open: true });
    };

    submitForm = async (submitEvent) => {
        submitEvent.preventDefault();
        this.setState({ btnDisabled: true });

        const data = serialize(submitEvent.target, { hash: true });
        await this.props.EnqueteStore.addSubmission(data, this.props.id);
        if (this.props.EnqueteStore.success) {
            this.openModal(true);
            this.setState({ btnDisabled: false });
            localStorage.setItem('survey' + this.props.id, true);
        }
        else {
            this.setState({ btnDisabled: false });
        }
    };

    render() {
        const { form_fields, submit_button_text, submitted_text, thank_you_title, thank_you_text, conditions, id } = this.props;
        return <>
            {(!localStorage.getItem('survey' + id)) ? <Form className="survey" onSubmit={this.submitForm}>
                {/* TODO verplaats naar eigen component en gebruik switch statement */}

                {form_fields.map((field) => <EnqueteFormField key={field.id} enqueteId={this.props.id} {...field} />)}

                {conditions && <ConditionCheckBox id={id} {...conditions} />}

                <FormGroup className="text-right">
                    <Button disabled={this.state.btnDisabled}>{submit_button_text}</Button>
                </FormGroup>
            </Form> : <p>{submitted_text}</p>}
            <SimpleModal
                closeModal={() => this.setState({ open: false })}
                open={this.state.open}
                title={thank_you_title}
            >{thank_you_text}</SimpleModal>
        </>;
    }
}

export default inject("EnqueteStore")(observer(EnqueteForm));