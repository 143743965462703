import React, { useEffect, useState } from "react";
import * as ELG from 'esri-leaflet-geocoder';
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FormFeedback, FormGroup, FormText, Label } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { LatLngBounds, GeoJSON } from 'leaflet';
import { coordsToLatLng } from "utils/geoHelpers";

function PositionSelecter({ label, name, children, onChange, error, value: initialValue, help_text, placeholder, PageStore: { details }, ...inputProps }) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState();

    const filterBy = () => true;

    useEffect(() => {
        if (!initialValue) return;
        ELG.reverseGeocode().latlng(GeoJSON.latLngToCoords(initialValue).reverse()).run((error, result) => {
            setValue({ text: result.address.Match_addr, coordinates: initialValue });
        })
    }, [])

    const maxBounds = new LatLngBounds(coordsToLatLng(details.upper_left.coordinates), coordsToLatLng(details.lower_right.coordinates));

    const handleSearch = (query) => {
        setIsLoading(true);
        ELG.geocode().text(query).within(maxBounds).run(function (err, results, response) {
            if (err) {
                return;
            }
            const options = results.results.map(result => ({
                text: result.text,
                coordinates: result.latlng
            }))
            setOptions(options)
        });

    }

    return <FormGroup>
        <Label for={name}>{label}</Label>
        <AsyncTypeahead
            className={error && 'is-invalid'}
            isInvalid={!!error}
            {...inputProps}
            onChange={value => {setValue(value[0]); return onChange(value[0])}}
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey="text"
            minLength={3}
            options={options}
            {...(value ? {selected: [value]} : {})}
            onSearch={handleSearch}
            placeholder={placeholder}
        />
        {error && <FormFeedback>{error[0]}</FormFeedback>}
        {help_text && <FormText>{help_text}</FormText>}
    </FormGroup>
}

PositionSelecter.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.shape({}),
    onChange: PropTypes.func,
    error: PropTypes.string,
    value: PropTypes.string,
    help_text: PropTypes.string,
    placeholder: PropTypes.string,
    PageStore: PropTypes.shape({
        details: PropTypes.shape({
            upper_left: PropTypes.shape({
                /**
                 * Coordinates of the map center
                 */
                coordinates: PropTypes.object
            }),
            lower_right: PropTypes.shape({
                /**
                 * Coordinates of the map center
                 */
                coordinates: PropTypes.object
            }),
            image_map_layers: PropTypes.object,
            esri_map_layers: PropTypes.object,
            respond_form: PropTypes.object,
            base_layer: PropTypes.string,
            introduction_button: PropTypes.string,
            autoplay: PropTypes.bool,
            visibility_legend: PropTypes.bool,
            visibility_maplayers: PropTypes.bool,
            visibility_options: PropTypes.bool,
            toggle_zoom: PropTypes.bool
        })
    })
};


export default inject('PageStore')(observer(PositionSelecter));