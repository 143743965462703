import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";


function Footerlinks(
    {
        copyright,
        disclaimer_url,
        disclaimer_text,
        privacy_url,
        privacy_text,
        hyperlink1_url,
        hyperlink2_url,
        hyperlink3_url
    }) {

    function getYear() {
        return new Date().getFullYear();
    }

    return (
        <div className="footer-links footer-links col-12 col-md-6 text-center text-md-left">
            <ul className="list-inline">

                <li className="list-inline-item footer-links-copyright">
                    {getYear()} © <span dangerouslySetInnerHTML={{__html: copyright}}/>
                </li>

                {disclaimer_url &&
                <li className="list-inline-item">
                    <Link to={disclaimer_url.meta.slug}>{disclaimer_text || disclaimer_url.title}</Link>
                </li>
                }
                {privacy_url &&
                <li className="list-inline-item">
                    <a href={privacy_url.file} rel="noopener noreferrer" target="_blank">{privacy_text || privacy_url.title}</a>
                </li>
                }

                {hyperlink1_url &&
                <li className="list-inline-item">
                    <Link to={hyperlink1_url.meta.slug}>{hyperlink1_url.title}</Link>
                </li>
                }
                {hyperlink2_url &&
                <li className="list-inline-item">
                    <Link to={hyperlink2_url.meta.slug}>{hyperlink2_url.title}</Link>
                </li>
                }

                {hyperlink3_url &&
                <li className="list-inline-item">
                    <Link to={hyperlink3_url.meta.slug}>{hyperlink3_url.title}</Link>
                </li>
                }

            </ul>
        </div>
    )
}


Footerlinks.propTypes = {
    copyright: PropTypes.string,
    disclaimer_url: PropTypes.object,
    disclaimer_text: PropTypes.string,
    privacy_url: PropTypes.object,
    privacy_text: PropTypes.string,
    hyperlink1_url: PropTypes.object,
    hyperlink2_url: PropTypes.object,
    hyperlink3_url: PropTypes.object,
};


export default Footerlinks;