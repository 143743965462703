//import "core-js/stable";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as MobxProvider } from 'mobx-react';
import { configure } from "mobx";
// import TagManager from 'react-gtm-module';
// import { gtmId } from 'utils/envHelpers';
import { IntlProvider } from 'react-intl';
import api from 'services/api';

import App from './App';
import * as serviceWorker from './serviceWorker';
import stores from './stores/stores';

configure({ enforceActions: "observed" });

// const tagManagerArgs = {
//     gtmId: gtmId
// }
// TagManager.initialize(tagManagerArgs)

api.Messages.all().then(i18nConfig => {
    window.LOCALE = i18nConfig.locale // TODO remove if better fix for coordstolatlng method

    ReactDOM.render(
        <IntlProvider
            locale={i18nConfig.locale}
            defaultLocale='nl'
            messages={i18nConfig.messages}
        >
            <MobxProvider {...stores}>
                <App />
            </MobxProvider>
        </IntlProvider>,
        document.getElementById('root')
    );
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
