import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import { injectIntl  } from "react-intl";

function Main({ children, intl }) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1,shrink-to-fit=no" />
                <title>{intl.messages.main_loader_text}</title>
            </Helmet>
            {children}
        </>
    );
}

Main.propTypes = {
    CommonStore: PropTypes.shape({
        title: PropTypes.string
    }),
    intl: PropTypes.shape({
        messages: PropTypes.object
    }),
    children: PropTypes.node
};

export default injectIntl(Main);