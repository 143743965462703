import React from 'react';
import PropTypes from 'prop-types';
import POIIcon from 'components/POIIcon/POIIcon';
import { PropTypes as MobxPropTypes } from 'mobx-react';

const ClusterIcon = ({ type, markers, content }) => <span>
    {type === 'poi' ? (
        <div data-pois={content} className="poi-group">{markers.map((marker, index) => <POIIcon key={index} {...marker} />)}</div>
    ) : (
        <span className="cluster">{content}</span>
    )}
</span>;

ClusterIcon.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string, PropTypes,
    markers: MobxPropTypes.observableArray
};

export default ClusterIcon;