import PropTypes from 'prop-types';
import { dynamicMapLayer } from 'esri-leaflet';
import { withLeaflet, MapLayer } from "react-leaflet";

class EsriMapLayer extends MapLayer {
    createLeafletElement({ layer, zIndex }) {
        const options = {
            url: `${layer.url}/${layer.token ? `?token=${layer.token}` : ""}`,
            format: 'png32',
            transparent: true,
            f: "image",
            layers: layer.layers ? [layer.layers.split('|')] : null,
            zIndex: zIndex,
        };
        const newLayer = new dynamicMapLayer(options);
        if (layer.feature_popup) {
            newLayer.bindPopup((err, featureCollection) => {
                if (featureCollection.features.length) {
                    const properties = layer.feature_popup.split(',')
                    const selectedFeature = featureCollection.features.pop()
                    const content = properties.map(property => {
                        if (Object.prototype.hasOwnProperty.call(selectedFeature.properties, property) && selectedFeature.properties[property] !== "Null") {
                            return `<p>${property}: ${selectedFeature.properties[property]}</p>`;
                        } else {
                            return "";
                        }
                    }).join('')
                    return content;
                }
            });
        }
        return newLayer;
    }
}

EsriMapLayer.propTypes = {
    layer: PropTypes.shape({
        url: PropTypes.string.isRequired,
        token: PropTypes.string,
        layers: PropTypes.string
    }).isRequired,
    zIndex: PropTypes.number
};

export default withLeaflet(EsriMapLayer);