import React from "react";
import PropTypes from "prop-types";
import EnqueteForm from "./EnqueteForm/EnqueteForm";

const Enquete = function ({ title, intro, ...props }) {
    return <div>
        <h2>{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: intro }} />
        <EnqueteForm {...props} />
    </div>
};

Enquete.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.string,
}

export default Enquete;