import React from 'react';
import PropTypes from "prop-types";

function Heading(content) {
    return (
        <h2 className={content.data.size + " mb-0"}>
            {content.data.text}
        </h2>
    );
}

Heading.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({
            size: PropTypes.string,
            heading_text: PropTypes.string,
            block_theme: {
                select_theme: PropTypes.string
            },
            margin_bottom: {
                select_margin_bottom: PropTypes.string
            },
            text_alignment: {
                select_text_alignment: PropTypes.string
            }

        })
    })
};
export default Heading;
