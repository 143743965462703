import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";

class UnsubscribePage extends Component {
    static propTypes = {
        ResponseStore: PropTypes.shape({
            unsubscribe: PropTypes.func,
            loading: PropTypes.bool,
            error: MobxPropTypes.observableObject
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                token: PropTypes.string
            })
        })
    };

    componentDidMount() {
        this.props.ResponseStore.unsubscribe(this.props.match.params.token);
    }

    render() {
        const { loading, error } = this.props.ResponseStore;
        if (loading) {
            return null;
        } else if (!error.status) {
            return (
                <div className="container mt-4">
                    <h1>U bent succesvol uitgeschreven.</h1>
                </div>
            )
        } else {
            return (
                <div className="container mt-4">
                    <p>Uitschrijflink is niet herkend, controleer of u de link uit de email goed heeft overgenomen</p>
                </div>
            )
        }
    }
}

export default withRouter(inject('ResponseStore')(observer(UnsubscribePage)));