import { decorate, observable, action, reaction } from "mobx";


/**
 * Store used to control html head content
 */
export class CommonStore {
    /**
     * Website title
     * @type {string}
     */
    title = "";

    /**
     * Auth token
     * @type {string}
     */
    token = window.localStorage.getItem('jwt');

    constructor() {
        this.title = "...Loading";
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        )
    }

    setToken = (token) => {
        this.token = token;
    }

    setTitle = (title) => {
        this.title = title;
    }
}

decorate(CommonStore, {
    title: observable,
    token: observable,
    setToken: action,
    setTitle: action
});


export default new CommonStore();