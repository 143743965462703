import React from "react";
import PropTypes from "prop-types";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Badge } from 'reactstrap';
import SVGIcons from "components/svgIcons/svgIcons";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ResponseItem from "components/ResponseList/ResponseItem";
// import SVGIcons from "../svgIcons/svgIcons";
import { RESPOND_ICONS } from "../../constants";
/**
 * Respond Partial
 *
 * @description
 * Renders a partial window with response details as map overlay
 */
function RespondPartial({ ResponseStore: { selectedResponse, responses }, selectedLayer, history, match, children,togglePanel }) {
    const uniqueMoods = [...new Set(responses.filter(response => response.layer_id === selectedLayer.id).map(response => response.mood))];
    const selectedResponseParent = selectedResponse.mood

    return (
        <div id="map-response" className={`map-response ${selectedResponse.id ? ' map-response-selected--open ' : ''}`}>

            <div className="map-response-intro">
                <p><FormattedMessage id="responselist_intro" /></p>
                {children}
            </div>
            <div className="map-response-responses">
            <p className="h3 map-response-responses__title"><FormattedMessage id="responselist_title" /></p>
                {uniqueMoods.map((mood, index) => {

                    let filteredRespones = responses
                        .filter(response => response.mood === mood)
                        .filter(response => response.layer_id === selectedLayer.id)
                    return (

                        <details className="map-response-group" key={index} open={!selectedResponseParent || selectedResponseParent === mood}>
                            <summary className="map-response-group-head">
                                <SVGIcons className="map-response-item-icon" name={RESPOND_ICONS[mood]} width={32} fill="currentColor" />
                                <span className="map-response-group-head-title">
                                    {mood}
                                    <Badge color="white">{filteredRespones.length}</Badge>
                                </span>

                                <span className="map-response-group-toggle">
                                    <FontAwesomeIcon title="toggle repsonses" icon={faChevronDown} />
                                </span>

                            </summary>
                            {filteredRespones.map((responseItem, index2) =>
                                <ResponseItem key={index2} {...responseItem} selected={selectedResponse.id === responseItem.id} history={history} match={match} togglePanel={togglePanel} />
                            )}
                        </details>
                    );
                })}
            </div>
        </div>
    )
}

RespondPartial.propTypes = {
    ResponseStore: PropTypes.shape({
        /**
         * The selected response
         */
        selectedResponse: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
            text: PropTypes.string,
            has_photo: PropTypes.bool,
            mood: PropTypes.string,
            client_name: PropTypes.string,
            client_response: PropTypes.string,
            involvement: PropTypes.shape({
                name: PropTypes.string
            }),
        }),
        responses: PropTypes.objectOf(PropTypes.shape({
            /**
             * Id of the response
             */
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            /**
             * Response geographic location
             */
            location: PropTypes.shape({
                /**
                 * Coordinates of the response
                 */
                coordinates: PropTypes.array
            }),
        })),
    }),
    selectedLayer: MobxPropTypes.observableObject,
    match: PropTypes.object,
    history: PropTypes.object,
    children: PropTypes.node,
    parentPage: PropTypes.string,
    togglePanel: PropTypes.func,
    intl: PropTypes.shape({
        messages: PropTypes.shape({
            closebutton_text: PropTypes.string
        })
    })
};

export default injectIntl(inject('ResponseStore')(observer(RespondPartial)))