import { observable, action, decorate, runInAction } from "mobx"
import api from "services/api";

/**
 * Mobx store to load and control page content
 */
export class SearchStore {
    /**
    * searchresults loading state
     * @type {boolean}
     */
    loading = false;
    /**
     * search results
     * @type {Array}
     */
    searchresults = []
    /**
     * search query
     * @type {string}
     */
    searchString = ''
    /**
     * number of search results
     * @type {number}
     */
    count = 0;
    /**
     * Error message
     * @type {string}
     */
    error = '';

    /**
     * Get searchresults
     * @param search String
     * @return {Promise<void>}
     */
    getSearchresults = async (search) => {
        if (!this.loading) {
            this.searchString = search;
        }
        this.loading = true;
        try {
            let response = await api.Search.getSearchresults(search);
            runInAction(() => {
                this.searchresults = response.items;
                this.count = response.meta.total_count;
                this.loading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
            })
        }
    }
}


decorate(SearchStore, {
    loading: observable,
    searchresults: observable,
    count: observable,
    searchString: observable,
    setSearchString: action,
    getSearchresults: action
});

export default new SearchStore();