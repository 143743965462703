import React from "react";
import { FormGroup, CustomInput, Col } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

function ConditionCheckBox({ id, file, intl }) {
    return <FormGroup row >
        <Col sm={{ size: 9, offset: 3 }}>
            <CustomInput
                id={'enquete_' + id + '_conditions'}
                defaultChecked={false}
                required={true}
                type="checkbox"
                name="conditions"
                value=""
                label={intl.messages.customcheckbox_conditions_label}
            >
                <a rel="noopener noreferrer" target="_blank" href={file}> <FormattedMessage id="customcheckbox_conditions_text" /></a>
            </CustomInput>
        </Col>
    </FormGroup>
}

ConditionCheckBox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    file: PropTypes.string,
    intl: PropTypes.shape({
        messages: PropTypes.shape({
            customcheckbox_conditions_label: PropTypes.string
        })
    }),
}

export default injectIntl(ConditionCheckBox);