import {divIcon} from "leaflet";
import ReactDOMServer from 'react-dom/server';

const MapIcon = (icon) => {
    return divIcon({
        className: "marker-icon",
        iconAnchor: [24, 40],
        popupAnchor: [0, -30],
        tooltipAnchor: [0, -25],
        iconSize: null,
        html: ReactDOMServer.renderToString(icon)
    });
};

export default MapIcon;