import React from 'react';
import {PropTypes} from "mobx-react";
import CollapseList from "../../../components/CollapseList/CollapseList";

function CollapseBlock({items}) {

return (<>
           <CollapseList list={items}  />
        </>
    );
}

CollapseBlock.propTypes = {
    items: PropTypes.observableArray
};

export default CollapseBlock;