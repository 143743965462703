import React from "react";
import PropTypes from "prop-types";
import { CustomInput, FormFeedback, FormGroup } from "reactstrap";

function Checkbox({ name, value, onChange, children, error, ...inputProps }) {
    return <FormGroup check>
        <CustomInput id={name} invalid={!!error} name={name} label={children} type="checkbox" checked={value}
            onChange={(e) => onChange(name, e.target.checked)}  {...inputProps}>
            {error && <FormFeedback>{error[0]}</FormFeedback>}
        </CustomInput>
    </FormGroup>
}

Checkbox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.node,
    error: PropTypes.object
}

export default Checkbox;