import {imageOverlay, LatLng, LatLngBounds} from "leaflet";
import {MapLayer, withLeaflet} from "react-leaflet";

class ImageLayer extends MapLayer{
    createLeafletElement(props) {
        const layer = props.layer;
        if(layer.image_layer.meta.download_url) {
            const bounds = new LatLngBounds(new LatLng(layer.min_lat, layer.min_long), new LatLng(layer.max_lat, layer.max_long));
            return new imageOverlay(layer.image_layer.meta.download_url, bounds, {zIndex: props.zIndex});
        }
    }
}

export default withLeaflet(ImageLayer);