import React from "react"
import PropTypes from "prop-types";
import { Label } from "reactstrap";

function EnqueteFormLabel({ labelfor, question, required }) {
    return <Label htmlFor={labelfor} className={required && "required"}>
        <span className="mb-0" dangerouslySetInnerHTML={{ __html: question }} />
    </Label>
}

EnqueteFormLabel.propTypes = {
    labelfor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    question: PropTypes.string,
    required: PropTypes.bool
}

export default EnqueteFormLabel;