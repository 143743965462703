import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Collapse, CardBody, Card} from 'reactstrap';

function CollapseItem({answer, question}) {
    const [collapse, setCollapse] = useState(false);

    return (
        <div className="faq-item" aria-expanded={collapse}>
            <Collapse isOpen={collapse} aria-hidden={!collapse}>
                <Card>
                    <CardBody className="text-dark" dangerouslySetInnerHTML={{__html: answer}}/>
                </Card>
            </Collapse>
            <button onClick={() => setCollapse(!collapse)} className="faq-item-head">
                {question}
            </button>

        </div>
    );
}

CollapseItem.propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string
};

export default CollapseItem;