import React, { Component } from "react";
import { Button, Form, FormGroup, Col, FormFeedback, Row, Label, Input, FormText } from "reactstrap";

import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import FieldGroup from "../../FieldGroup/FieldGroup";
import LimitedTextarea from "../../LimitedTextarea/LimitedTextarea";
import SVGIcons from "../../svgIcons/svgIcons";
import Checkbox from "../../Checkbox/Checkbox";
import CommentRadio from "./CommentRadio";
import { sortByOrder } from "../../../utils/sortHelpers";
import { RESPOND_ICONS } from "../../../constants";
import PositionSelecter from "../../PositionSelecter/PositionSelecter";

class RespondForm extends Component {
    static propTypes = {
        allow_photo_upload: PropTypes.bool,
        allow_trusted_responses: PropTypes.bool,
        submit_button_text: PropTypes.string,
        cancel_button_text: PropTypes.string,
        moods: MobxPropTypes.observableArray,
        involvements: PropTypes.object,
        themes: PropTypes.object,
        privacy_statement: PropTypes.shape({
            file: PropTypes.string
        }),
        theme_label: PropTypes.string,
        text_label: PropTypes.string,
        custom_question_text: PropTypes.string,
        custom_question_help_text: PropTypes.string,
        custom_question_type: PropTypes.oneOf(['dropdown', 'multiline']),
        custom_question_options: PropTypes.string,
        intl: PropTypes.shape({
            messages: PropTypes.object
        }),
        ResponseStore: PropTypes.shape({
            response: PropTypes.object,
            error: PropTypes.object,
            success: PropTypes.bool,
            updateProperty: PropTypes.func,
            addResponse: PropTypes.func,
            updatePosition: PropTypes.func,           
        }),
        closeModal: PropTypes.func
    };

    state = {
        btnDisabled: false
    };

    submitForm = async (e) => {
        e.preventDefault();

        this.setState({ btnDisabled: true });
        let response = await this.props.ResponseStore.addResponse();

        if (this.props.ResponseStore.success) {
            this.props.closeModal(true, response.id);
            this.setState({ btnDisabled: false });
        }
        else {
            this.setState({ btnDisabled: false });
        }
    };

    render() {
        const {
            allow_photo_upload,
            allow_trusted_responses,
            submit_button_text,
            cancel_button_text,
            moods,
            involvements,
            themes,
            closeModal,
            privacy_statement,
            theme_label,
            text_label,
            custom_question_help_text,
            custom_question_options,
            custom_question_text,
            custom_question_type,
            ResponseStore: {
                response,
                updateProperty,
                error,
                updatePosition
            },
            intl
        } = this.props;
        let themeLabel = '';

        function onChange(name, value) {
            updateProperty(name, value);
        }

        function onChangeSelect(name, value) {
            updateProperty(name, {
                value: value
            });
        }

        function changeFile() {
            var imagefile = document.querySelector('#photo');

            if (imagefile.files[0]) {
                document.getElementById("filename").innerHTML = imagefile.files[0].name;
            }

            updateProperty('photo', imagefile.files[0]);
        }

        let moodString;

        if (moods.length === 1) {
            onChange('mood', moods[0])
        } else {
            // eslint-disable-next-line no-console
            const moodsCopy = moods.toJS();
            moodString = `Heeft u een ${moodsCopy.slice(0, -1).join(', ')} of ${moodsCopy.slice(-1)}?`;
        }

        if (theme_label) {
            themeLabel = theme_label;
        } else if (intl.messages.respondform_theme_label) {
            themeLabel = <FormattedMessage id="respondform_theme_label" />;
        } else {
            themeLabel = `Waarover gaat uw ${response.mood ? response.mood : 'reactie'}?`;
        }

        return (
            <Form onSubmit={this.submitForm}>
                {response.layer_id && <PositionSelecter
                    name="position"
                    label={<span><FormattedMessage id="respondform_position_label" /></span>}
                    value={response.position}
                    placeholder={this.props.intl.messages.respondform_position_placeholder}
                    error={error.location}
                    onChange={updatePosition} />}

                {moods.length > 1 && <>
                    <Row>
                        <Col xs={12}>
                            {intl.messages.respondform_title ? <FormattedMessage id="respondform_title" /> : moodString}
                        </Col>
                    </Row>
                    <FormGroup row className="no-gutters">
                        {moods.map((mood, index) => <Col key={mood}>
                            <CommentRadio
                                label={mood}
                                name="mood"
                                error={error.mood}
                                value={mood}
                                onChange={onChange}
                            >
                                <SVGIcons className={`map-response-icon map-response-${mood} mr-1`} name={RESPOND_ICONS[mood]} width={40} height={40}
                                    fill="currentColor" />
                                <span>{mood}</span>
                                {error.mood && index === 0 && <FormFeedback>{error.mood[0]}</FormFeedback>}
                            </CommentRadio>
                        </Col>)}
                    </FormGroup>
                </>}

                <FieldGroup
                    name="theme"
                    label={themeLabel}
                    value={response.theme.value}
                    type="select"
                    onChange={onChangeSelect}
                    error={error.theme}
                >
                    <option value="" disabled hidden>{intl.messages.respondform_theme_defaultOption}</option>
                    {themes.sort(sortByOrder).map(theme => <option
                        key={theme.value}
                        value={theme.value}>
                        {theme.name}
                    </option>)}
                </FieldGroup>

                <LimitedTextarea
                    limit={500}
                    value={response.text}
                    name="text"
                    label={text_label || intl.messages.respondform_reaction_label}
                    placeholder={intl.messages.respondform_reaction_placeholder}
                    onChange={onChange}
                    error={error.text}
                />

                {
                    allow_photo_upload && <FormGroup>
                        <Label>{intl.messages.respondform_photo_label}</Label>
                        <FormText color="muted">
                            {intl.messages.respondform_photo_privacy}
                        </FormText>

                        <Label htmlFor="photo" className="btn btn-sm btn-primary mr-1">{intl.messages.respondform_photo_upload}
                            <Input type="file" className="d-none" name="photo" id="photo" accept="image/*" onChange={changeFile} />
                        </Label>
                        <small id="filename"></small>


                        <FormText color="muted">
                            {intl.messages.respondform_photo_max}
                        </FormText>
                    </FormGroup>
                }

                {allow_trusted_responses && <FormGroup tag="fieldset">
                    <Checkbox error={error.trusted} value={response.trusted} onChange={onChange} name="trusted">
                        <FormattedMessage id="respondform_is_trusted" />
                    </Checkbox>
                </FormGroup>}

                <FieldGroup
                    name="involvement"
                    label={this.props.intl.messages.respondform_involvement_label}
                    value={response.involvement.value}
                    type="select"
                    onChange={onChangeSelect}
                    error={error.involvement}
                >
                    <option value="" disabled hidden>{intl.messages.respondform_involvement_defaultOption}</option>
                    {involvements.sort(sortByOrder).map(involvement => <option
                        key={involvement.value}
                        value={involvement.value}>
                        {involvement.name}
                    </option>)}
                </FieldGroup>

                {custom_question_text && custom_question_type === 'multiline' ? <FieldGroup
                    value={response.extra_question_answer}
                    name="extra_question_answer"
                    label={custom_question_text}
                    placeholder={custom_question_help_text}
                    onChange={onChange}
                    type="textarea"
                    error={error.custom_question_text}
                /> : custom_question_text && custom_question_type === 'dropdown' ? <FieldGroup
                    name="extra_question_answer"
                    label={custom_question_text}
                    value={response.extra_question_answer}
                    type="select"
                    onChange={onChange}
                    error={error.extra_question_answer}
                >
                    <option value="" disabled hidden>{custom_question_help_text}</option>
                    {custom_question_options.split(",").map(option => <option
                        key={option}
                        value={option}>
                        {option}
                    </option>)}
                </FieldGroup> : null}

                <FieldGroup
                    name="name"
                    label={<span><FormattedMessage id="respondform_name_label" /> <span className="text-muted">(<FormattedMessage id="respondform_optional_label" />)</span></span>}
                    value={response.name}
                    type="text"
                    placeholder={this.props.intl.messages.respondform_name_placeholder}
                    error={error.name}
                    onChange={onChange} />

                <FieldGroup
                    name="email"
                    label={this.props.intl.messages.respondform_email_label}
                    value={response.email}
                    type="email"
                    error={error.email}
                    placeholder={this.props.intl.messages.respondform_email_placeholder}
                    onChange={onChange} />

                <FieldGroup
                    name="zip_code"
                    label={this.props.intl.messages.respondform_zipcode_label}
                    value={response.zip_code}
                    type="text"
                    placeholder={this.props.intl.messages.respondform_zipcode_placeholder}
                    maxLength="7"
                    error={error.zip_code}
                    onChange={onChange} />

                <FormGroup tag="fieldset">
                    <Checkbox error={error.privacy} value={response.privacy} onChange={onChange} name="privacy">
                        <FormattedMessage id="respondform_privacystatement_text_prefix" /> <a href={privacy_statement.file} rel="noopener noreferrer"
                            target="_blank"><FormattedMessage id="respondform_privacystatement_text" /></a>
                    </Checkbox>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Checkbox error={error.sendmail} value={response.sendmail} onChange={onChange} name="sendmail">
                        <FormattedMessage id="respondform_sendmail_text" />
                    </Checkbox>
                </FormGroup>

                <Button className="btn-cancel" color="white"
                    onClick={() => closeModal(false)}>{cancel_button_text}</Button>
                <Button disabled={this.state.btnDisabled} className="float-right" submit="true" color="primary">{submit_button_text}</Button>
            </Form >
        )
    }
}

export default injectIntl(inject('ResponseStore', 'PageStore')(observer(RespondForm)));
