import React from "react"
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import MainMenu from "./MainMenu/MainMenu";

/**
 * Header component used on every page
 */
const Header = ({ routes, PageStore }) => {
    const navItems = routes.filter(route => route.meta.show_in_menus);
    const rootPage = routes.find(route => route.meta.parent == null);

    const baselayer = (!PageStore.details) ? '' : PageStore.details.base_layer;
    const pageTypeItem = (!PageStore.details.meta) ? '' : String(PageStore.details.meta.type);

    return (
        <header data-pagetype={(baselayer ? 'map' : pageTypeItem)}>
            <MainMenu rootPage={rootPage} items={navItems} />
        </header>
    )
};

Header.propTypes = {
    /**
     * Array of routes
     */
    routes: PropTypes.objectOf(PropTypes.shape({
        /**
         * Metadata for each route
         */
        meta: PropTypes.shape({
            /**
             * Show route in menu
             */
            show_in_menus: PropTypes.bool,
            /**
             * Route type
             */
            type: PropTypes.string
        })
    })),
    PageStore: PropTypes.shape({
        details: PropTypes.shape({
            base_layer: PropTypes.string,
            meta: PropTypes.shape({
                type: PropTypes.string
            })
        })
    })
};

export default inject("PageStore")(observer(Header))