import React, { useEffect } from 'react';
import { LayersControl, withLeaflet } from "react-leaflet";
import PropTypes from "prop-types";
import { injectIntl } from 'react-intl';
import BaseMapLayer from "../../MapLayers/BaseMapLayer/BaseMapLayer";

const { BaseLayer } = LayersControl;

export const layerEnum = {
    "Basiskaarten/Canvas": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Canvas/MapServer',
        img: "/img/lightgray.jpg",
        enabled: 'layer_lightgrey',
        locale: 'nl-NL',
        label: 'Lichtgrijs canvas',
    },
    "Basiskaarten/Donkergrijze_Canvas": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Donkergrijze_Canvas/MapServer',
        img: "/img/darkgray.jpg",
        enabled: 'layer_darkgrey',
        locale: 'nl-NL',
        label: 'Donkergrijs canvas',
    },
    "Basiskaarten/Topo": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Topo/MapServer',
        img: "/img/topographic.jpg",
        enabled: 'layer_topo',
        locale: 'nl-NL',
        label: 'Topo',

    },
    "Luchtfoto/Luchtfoto": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Luchtfoto/Luchtfoto/MapServer',
        img: "/img/satellite.png",
        enabled: 'layer_satellite',
        locale: 'nl-NL',
        label: 'Luchtfoto',
    },
    "World_Street_Map": {
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer',
        enabled: 'layer_street',
        img: "/img/street.png",
        label: 'Street',
    },
    "World_Topo_Map": {
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer',
        img: "/img/topographic.jpg",
        enabled: 'layer_topo',
        label: 'Topo',
    },
    "World_Imagery": {
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
        img: "/img/satellite.png",
        enabled: 'layer_satellite',
        label: 'Satellite',
    }
}

/**
 * Renders the background layer control element
 *
 * @class BackgroundControl
 * @extends {Component}
 */
const BackgroundControl = function ({ base_layer, intl, leaflet, ...props }) {
    const layersToggle = document.getElementsByClassName('leaflet-control-layers-toggle');

    const setLayer = (layerName) => {

        layersToggle[0].setAttribute('style', getStyleFromLayerName(layerName))
    }

    const getStyleFromLayerName = (layerName) => {
        return `background-image: url(${process.env.PUBLIC_URL}${layerEnum[layerName].img})`
    }

    useEffect(() => {
        if (leaflet.map._loaded && base_layer) {
            setLayer(base_layer)
        }
        leaflet.map.on("baselayerchange", (layer) => {
            const layerName = layer.name.match(/title=(\w*\/?\w*)/i)[1];
            return setLayer(layerName)
        })
        return () => {
            leaflet.map.off("baselayerchange")
        }
    }, [leaflet.map])

    const layers = [
        props.layer_lightgrey,
        props.layer_darkgrey,
        props.layer_street,
        props.layer_topo,
        props.layer_satellite
    ];


    return (
        <LayersControl collapsed={false} position="topleft">
            {Object.keys(layerEnum).filter(name => {
                const layer = layerEnum[name];
                return layer.locale === intl.locale || typeof layer.locale === 'undefined'
            }).map((name, index) => {
                const layer = layerEnum[name];
                // eslint-disable-next-line eqeqeq
                if (layers[index] == true) {
                    return (
                        <BaseLayer
                            key={name}
                            checked={layer.url.includes(base_layer) ? true : false}
                            name={`<span data-text="${intl.messages.maplayercontainer_background_text}" class="btn btn-sm ${base_layer === layer.url ? ' btn-secondary ' : ' btn-outline-secondary '} " title=${name}>${intl.messages[layer.enabled] ? intl.messages[layer.enabled] : layer.label} </span>`}>
                            <BaseMapLayer url={layer.url} />
                        </BaseLayer>
                    )
                }
                return null;
            })}
        </LayersControl>
    );
}

BackgroundControl.propTypes = {
    /**
     * Leaflet object
     */
    leaflet: PropTypes.shape({
        map: PropTypes.object
    }),
    /**
     * Base layer name
     */
    base_layer: PropTypes.string,
    intl: PropTypes.shape({
        locale: PropTypes.string,
        messages: PropTypes.object
    }),
    layer_lightgrey: PropTypes.bool,
    layer_darkgrey: PropTypes.bool,
    layer_street: PropTypes.bool,
    layer_topo: PropTypes.bool,
    layer_satellite: PropTypes.bool
};

export default withLeaflet(injectIntl(BackgroundControl));