import React from 'react';
import PropTypes from "prop-types";
import InnerHTML from 'dangerously-set-html-content'

function Html(content) {
    return (
        <div className="rte">
            <InnerHTML html={content.data} />
        </div>

    );
}

Html.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.string
    })
};
export default Html;