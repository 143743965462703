import React from "react";
import PropTypes from "prop-types";
import Control from 'react-leaflet-control';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import LayerSelector from "../../../components/MapLayers/LayerSelector/LayerSelector";

function MapLayerContainer({ mapLayers, intl, ...props }) {
    return (
        <>
            {mapLayers.length > 1 &&
                <Control position="topleft" tag="div">
                    <div id="map-layers-inside" className="map-layers-inside">
                        <span><FormattedMessage id="maplayercontainer_foreground_text" /></span>
                        <div className="map-layers-inside-map">
                            <LayerSelector
                                layers={mapLayers}
                                {...props}
                                vertical={true}
                            />
                        </div>
                    </div>
                </Control>
            }
            <Control position="topleft">
                <button aria-label={intl.messages.toggle_maplayers} title={intl.messages.toggle_maplayers} className="toggle-maplayers-desktop" onClick={(e) => props.togglePanel('layers')}>
                    <FontAwesomeIcon title={intl.messages.toggle_maplayers} icon={faLayerGroup} />
                </button>
            </Control>

        </>
    );
}

MapLayerContainer.propTypes = {
    selectedLayer: PropTypes.object,
    maxBounds: PropTypes.object,
    setSelectedLayer: PropTypes.func,
    mapLayers: PropTypes.object,
    togglePanel: PropTypes.func,
    intl: PropTypes.shape({
        messages: PropTypes.object
    }),
};


export default injectIntl(MapLayerContainer);
