import React from 'react';
import PropTypes from "prop-types";
import TimelineItemBlock from "./TimelineItem";


function Timeline(content) {

    const TimelineItem = content.data.items.map((listItem, index) =>
        <TimelineItemBlock
            key={index}
            timelineitem={listItem}
            iterator={index}
            theme={content.data.block_theme.select_theme !== '' ? content.data.block_theme.select_theme : 'white' }
        />
    );

    return (
        <ul className={"timeline " + (content.data.dekstop_layout === 'timeline-vertical' ? 'timeline-vertical' : 'timeline-horizontal')}>
            {TimelineItem}
        </ul>
    );
}

Timeline.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({

            block_theme : {
                select_theme: PropTypes.string
            },
            dekstop_layout : PropTypes.string,
            margin_bottom : {
                select_margin_bottom: PropTypes.string
            }

        })
    })
};
export default Timeline;


