const wagtailToFieldTypes = {
    "number": "number",
    "singleline": "text",
    "url": "url",
    "datetime": "datetime-local",
    "date": "date",
    "email": "email",
    "dropdown": "dropdown",
    "multiselect": "select",
    "checkbox": "checkbox",
    "checkboxes": "checkbox",
    "radio": "radio",
    "multiline": "textarea",
    "hidden": "hidden"
}

export default wagtailToFieldTypes