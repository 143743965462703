
import React from "react"
import PropTypes from "prop-types"
import { PropTypes as MobxPropTypes, inject, observer } from "mobx-react";
import MerText from "./MerText"
import MerBreadcrumbs from "./MerBreadcrumbs"


/**
 * Renders the Top component of the Mer page
 * Also handles switching of content and maplayers
 *
 * @exports
 */
function MerTree({ nodes, togglePanel, MerStore, MapLayerStore }) {

    const onEnterViewport = (node) => {
        setNodeContentBasedOnType(node)
    }

    const onLeaveViewport = (object, node) => {
        if (isScrollingToTop(object.currentPosition))
            setNodeContentBasedOnType(MerStore.getPreviousNode(node))
    }

    function isScrollingToTop(currentPosition) {
        return currentPosition === "below"
    }

    function setNodeContentBasedOnType(node) {
        if (node.map_content_block.length) {
            MerStore.clearContent()

            // wagtail foreign keys are always arrays
            const mapContent = node.map_content_block[0];

            if (mapContent.toggle) {
                MapLayerStore.setLayerToggle(mapContent)
            } else {
                MapLayerStore.setSelectedLayer(mapContent)
            }
        } else {
            MapLayerStore.clearSelectedLayer()
            if (node.image_content_block.length) {
                MerStore.setContent(node.image_content_block[0])
            } else if (node.table_content_block.length) {
                MerStore.setContent(node.table_content_block[0])
            } else {
                MerStore.clearContent()
            }
        }

    }

    return  <div className="map-mer-text">
            {MerStore.activeEffectTree.name &&
                <div className="map-mer-breadcrumbs p-2">
                    <MerBreadcrumbs node={MerStore.activeEffectTree} />
                </div>
            }

            <div className="map-mer-chapters px-2">
                <MerText onEnterViewport={onEnterViewport} onLeaveViewport={onLeaveViewport} nodes={nodes} togglePanel={togglePanel} />
            </div>

        </div>
}

MerTree.propTypes = {
    /**
     * List of MER nodes with text and dynamic content
     */
    nodes: PropTypes.oneOfType([MobxPropTypes.observableArray, PropTypes.array]),
    togglePanel: PropTypes.func,
    /**
     * Reference to the mobx MER store
     */

    MerStore: PropTypes.shape({
        /**
         * Set active dynamic content based on the left column text
         */
        setContent: PropTypes.func,
        getPreviousNode: PropTypes.func,
        clearContent: PropTypes.func,
        activeEffectTree: PropTypes.object,
    }),
    /**
     * Reference to the mobx Maplayer store
     */
    MapLayerStore: PropTypes.shape({
        /**
         * Selected layer based on left column text
         */
        setSelectedLayer: PropTypes.func,
        /**
         * Clear all layers from the map
         */
        setLayerToggle: PropTypes.func,
        clearSelectedLayer: PropTypes.func,
        mapLayers: PropTypes.any
    })
}

export default inject('MerStore', 'MapLayerStore')(observer(MerTree))
