
import React, { useState, Fragment, useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import axios from 'axios'
import { withRouter } from "react-router-dom";
import { injectIntl } from 'react-intl';
import {
  Map,
  GeoJSON
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import proj4leaflet from "proj4leaflet";
import BaseMapLayer from 'components/MapLayers/BaseMapLayer/BaseMapLayer';

const RD = new proj4leaflet.CRS('EPSG:28992',
  '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs', {
  origin: [-285401.92, 903401.92],
  resolutions: [3251.206502413005, 1625.6032512065026, 812.8016256032513, 406.40081280162565, 203.20040640081282, 101.60020320040641, 50.800101600203206, 25.400050800101603, 12.700025400050801, 6.350012700025401, 3.1750063500127004, 1.5875031750063502, 0.7937515875031751, 0.39687579375158755, 0.19843789687579377, 0.09921894843789689, 0.04960947421894844]
});

// to do:
// fitbounds auto


const GeoJSONBlock = ({ intl, data, history }) => {
  const [geojson, setGeojson] = useState();
  const mapRef = useRef();
  const customAreaRef = useRef();

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: clickToFeature
    })
      .bindTooltip(layer.feature.properties.naam, { permanent: true, interactive: true, opacity: 1, direction: layer.feature.properties.direction ? layer.feature.properties.direction : "center" });
    setTimeout(() => {
      layer.closeTooltip();
      layer.openTooltip(layer.getBounds().getCenter());
    }, 50);
  }

  const clickToFeature = (e) => {
    var layer = e.target;
    history.push(layer.feature.properties.url)
  }

  useEffect(() => {
    axios.get(data.geojson.meta.download_url)
      .then(res => {
        setGeojson(res.data)

        if (mapRef.current && customAreaRef.current) {

          const map = mapRef.current.leafletElement;  //get native Map instance

          const layer = customAreaRef.current.leafletElement;

          map.fitBounds(layer.getBounds().pad(0.5))
        }
      });
  }, [data.geojson.meta.download_url]);

  const baseMapUrl = intl.locale === 'nl-NL' ? `https://services.arcgisonline.nl/arcgis/rest/services/${data.baselayer}/MapServer` : `https://services.arcgisonline.com/ArcGIS/rest/services/${data.baselayer}/MapServer`


  return (
    <Fragment>
      {geojson &&
        <Map base_layer={baseMapUrl} crs={RD} center={[0, 0]} zoom="3" ref={mapRef} className="map--geojson" >
          <GeoJSON
            ref={customAreaRef}
            data={geojson}
            onEachFeature={onEachFeature}
          />
          <BaseMapLayer url={baseMapUrl} />
        </Map>
      }
    </Fragment>
  );
}


GeoJSONBlock.propTypes = {
  data: PropTypes.shape({
    geojson: PropTypes.shape({
      meta: PropTypes.shape({
        download_url: PropTypes.string
      })
    }),
    baselayer: PropTypes.string,
  }),
  intl: PropTypes.shape({
    locale: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  })
};

export default withRouter(injectIntl(GeoJSONBlock));

