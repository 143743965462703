import React from 'react';
import PropTypes from "prop-types";
import {Row, Col} from 'reactstrap';
import Blocks from '../Blocks';


function AsideParagraph(content) {
    return (

        <Row className="justify-content-top">
            <Col lg={4}>
                <aside>
                    <div className="rte" dangerouslySetInnerHTML={{__html: content.data.aside}}>
                    </div>
                </aside>
            </Col>
            <Col lg={8}>
                <Blocks disableLazyload nestedBlock blocks={content.data.body} />
            </Col>
        </Row>

    );
}

AsideParagraph.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({

            layout:PropTypes.string,
            aside:PropTypes.string,
            text:PropTypes.string,
            block_theme : {
                select_theme: PropTypes.string
            },
            margin_bottom : {
                select_margin_bottom: PropTypes.string
            }

        })
    })
};
export default AsideParagraph;


