import React from 'react'
import { FormattedMessage  } from "react-intl";
import './loader.css';

const Loader = () => <div className="react-loader">
    <div role="status">
        <span><FormattedMessage id="loader_loader_text" /></span>
    </div>
</div>;

export default Loader