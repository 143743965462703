import React, { Component } from "react";
import PropTypes from "prop-types";
import { DomEvent } from 'leaflet'
import Control from "react-leaflet-control";
import { Treebeard, decorators } from "react-treebeard";
import { withLeaflet } from "react-leaflet";
import EffectFilter from "./EffectFilter";
import EffectButton from "./EffectButton";

class EffectMenu extends Component {
    static propTypes = {
        baseRoute: PropTypes.string,
        node: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            meta: PropTypes.shape({
                slug: PropTypes.string
            }),
            children: PropTypes.array
        })
    }
    state = {
        data: {}
    }
    container = null;

    static getDerivedStateFromProps(props, state) {
        return {
            data: props.node
        }
    }

    onToggle = (node, toggled) => {
        const { data } = this.state;

        if (node.children) {
            node.toggled = toggled;
        }

        this.setState({ data: Object.assign({}, data) });
    }


    refContainer = (element) => {
        this.container = element;
        if (element) {
            DomEvent
                .disableClickPropagation(element)
                .disableScrollPropagation(element);
        }
    }

    render() {
        return <Control position="topright" className="mer-effect-menu">
            <div ref={this.refContainer} className="mer-effect-menu-items overflow-auto" >
                <div className="d-flex flex-column">
                    <h4 className="text-left m-0">Effecten</h4>
                    <EffectFilter />
                    <Treebeard
                        data={this.state.data.children}
                        onToggle={this.onToggle}
                        decorators={{ ...decorators, Header: EffectButton }}
                        style={{
                            tree: {
                                base: {
                                    backgroundColor: 'transparent',
                                    fontSize: "85%"
                                },
                                node: {
                                    link: {
                                        display: 'flex'
                                    },
                                    activeLink: {
                                        background: 'transparent'
                                    },
                                    subtree: {
                                        listStyle: 'none',
                                        paddingLeft: '19px'
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </Control >;
    }
}

export default withLeaflet(EffectMenu);