import React from 'react';
import PropTypes from "prop-types";
import SVGIcons from "../svgIcons/svgIcons";


function FooterSocialmedia(
    {
        facebook,
        instagram,
        linkedin,
        twitter,
        vimeo,
        youtube,
    }) {

    return (
        <div className="footer-socialmedia footer-socialmedia col-12 col-md-6 text-center text-md-right">
            <ul className="list-inline">
                {facebook &&
                <li className="list-inline-item facebook">
                    <a rel="noopener noreferrer" target="_blank" title="facebook" href={facebook}>
                        <SVGIcons className="footer-socialmedia-icon" name="facebook" width={100} fill="currentColor"/>
                    </a>
                </li>
                }
                {instagram &&
                <li className="list-inline-item instagram">
                    <a rel="noopener noreferrer" target="_blank" title="instagram" href={instagram}>
                        <SVGIcons className="footer-socialmedia-icon" name="instagram" width={100} fill="currentColor"/>
                    </a>
                </li>
                }
                {linkedin &&
                <li className="list-inline-item linkedin">
                    <a rel="noopener noreferrer" target="_blank" title="linkedin" href={linkedin}>
                        <SVGIcons className="footer-socialmedia-icon" name="linkedin" width={100} fill="currentColor"/>
                    </a>
                </li>
                }
                {twitter &&
                <li className="list-inline-item twitter">
                    <a rel="noopener noreferrer" target="_blank" title="twitter" href={twitter}>
                        <SVGIcons className="footer-socialmedia-icon" name="twitter" width={100} fill="currentColor"/>
                    </a>
                </li>
                }
                {vimeo &&
                <li className="list-inline-item vimeo">
                    <a rel="noopener noreferrer" target="_blank" title="vimeo" href={vimeo}>
                        <SVGIcons className="footer-socialmedia-icon" name="vimeo" width={100} fill="currentColor"/>
                    </a>
                </li>
                }
                {youtube &&
                <li className="list-inline-item youtube">
                    <a rel="noopener noreferrer" target="_blank" title="youtube" href={youtube}>
                        <SVGIcons className="footer-socialmedia-icon" name="youtube" width={100} fill="currentColor"/>
                    </a>
                </li>
                }
            </ul>
        </div>
    )
}


FooterSocialmedia.propTypes = {
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string,
    vimeo: PropTypes.string,
    youtube: PropTypes.string,
};


export default FooterSocialmedia;