// TODO remove after merge to docker-compose
if (!window._env_) {
    window._env_ = process.env
}

export const theme = window._env_.REACT_APP_THEME_NAME;

export const config = require("../css/themes/" + theme + "/config/config.json");

export const azure_instrumentation_key = window._env_.AZURE_INSTRUMENTATION_KEY;

export const site_title = window._env_.REACT_APP_SITE_TITLE;

export const gtmId = window._env_.REACT_APP_GOOGLE_TAG_MANAGER;