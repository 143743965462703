import React, { Component } from 'react';
import { Route, withRouter } from "react-router";

import PropTypes from "prop-types";
import { LatLngBounds } from 'leaflet';
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { coordsToLatLng } from "utils/geoHelpers";
import POILayer from 'components/POILayer/POILayer';
import withPage from "components/withPage/withPage";
import PageDefaultMap from "components/DefaultMap/PageDefaultMap";
import LayerText from "components/MapLayers/LayerText/LayerText";
import RespondOverlay from 'components/RespondOverlay/RespondOverlay';
import ResponseLayer from 'components/ResponseLayer/ResponseLayer';
import IntroTour from "components/IntroTour/IntroTour";
import RespondPartialController from 'scenes/RespondPartial/RespondPartialController';
import RespondPartial from 'scenes/RespondPartial/RespondPartial';

import MapLayerContainer from 'components/MapLayers/MapLayerContainer/MapLayerContainer';
import Loader from 'components/Loader/Loader';
import RespondModal from 'components/RespondModal/RespondModal';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faLayerGroup, faComment } from '@fortawesome/free-solid-svg-icons';

/**
 * Map Page
 *
 * @description
 * Renders the map page from wagtail including all components
 */
export class MapPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disableMapElements: false,
            panelOpen: '' //information, layers, responses
        };

        this.toggleMapElements = this.toggleMapElements.bind(this)
        this.togglePanel = this.togglePanel.bind(this)
    }

    toggleMapElements(attribute) {
        this.setState({
            disableMapElements: attribute
        })
    }


    togglePanel(e) {
        if (this.props.match.path) {

            const url = '';
            this.props.history.push(`${this.props.match.path}${url}`);
        }

        // toggle value
        this.setState({ panelOpen: (e === this.state.panelOpen) ? '' : e })
    }


    static propTypes = {
        /**
         * Page Store
         */
        PageStore: PropTypes.shape({
            /**
             * Map Page details
             */
            details: PropTypes.shape({
                upper_left: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                lower_right: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                image_map_layers: PropTypes.object,
                esri_map_layers: PropTypes.object,
                respond_form: PropTypes.object,
                base_layer: PropTypes.string,
                introduction: MobxPropTypes.observableObject,
                introduction_button: PropTypes.string,
                autoplay: PropTypes.bool,
                visibility_legend: PropTypes.bool,
                visibility_maplayers: PropTypes.bool,
                toggle_zoom: PropTypes.bool,
                center: PropTypes.shape({
                    coordinates: PropTypes.object
                }),
                layer_lightgrey: PropTypes.bool,
                layer_darkgrey: PropTypes.bool,
                layer_street: PropTypes.bool,
                layer_topo: PropTypes.bool,
                layer_satellite: PropTypes.bool,

            })
        }),
        MapLayerStore: PropTypes.shape({
            setMapLayers: PropTypes.func,
            selectedLayer: PropTypes.object,
            setSelectedLayer: PropTypes.func,
            mapLayers: PropTypes.object
        }),
        ResponseStore: PropTypes.shape({
            responses: MobxPropTypes.observableArray,
            setResponsePoi: PropTypes.func,
            setRespondForm: PropTypes.func,
            getAllResponses: PropTypes.func,
            setResponseLayer: PropTypes.func
        }),
        /**
         * React router match prop
         */
        match: PropTypes.object,
        /**
         * React router history prop
         */
        history: PropTypes.object,
        meta: PropTypes.shape({
            type: PropTypes.oneOf(['map.MapPage']),
        })
    };

    componentDidMount() {
        const { MapLayerStore: { setMapLayers, setSelectedLayer }, PageStore: { details } } = this.props;
        //line below is a bit ugly because we didnt manage to get the maplayers in one array from wagtail.
        const mapLayers = setMapLayers(details.image_map_layers.concat(details.esri_map_layers.slice()));
        setSelectedLayer(mapLayers.find(mapLayer => mapLayer.sort_order === 0));
    }

    setSelectedLayer = (layer) => {
        const { MapLayerStore: { setSelectedLayer }, match, history } = this.props;
        history.push(match.path);
        setSelectedLayer(layer);
    }

    beforeOpen = (next) => {
        this.props.ResponseStore.setRespondForm(this.props.PageStore.details.respond_form)
        this.props.ResponseStore.setResponseLayer(this.props.MapLayerStore.selectedLayer);
        next();
    }

    render() {
        const { PageStore: { details }, ResponseStore: { responses }, MapLayerStore: { selectedLayer, mapLayers }, match, history } = this.props;

        var layers = [
            details.layer_lightgrey,
            details.layer_darkgrey,
            details.layer_street,
            details.layer_topo,
            details.layer_satellite
        ];
        const activeLayers = layers.filter(item => item === true);

        const maxBounds = new LatLngBounds(coordsToLatLng(details.upper_left.coordinates), coordsToLatLng(details.lower_right.coordinates));
        return (
            selectedLayer.id ? <React.Fragment><main id="main-content" className={" map-page " + (this.state.disableMapElements ? " map-response-active " : "")} data-layer={details.base_layer} data-open={this.state.panelOpen ? this.state.panelOpen : 'default'}>

                {/* <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLayers" />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLegend" /> */}

                <div className="wb-map__column">
                    <LayerText togglePanel={this.togglePanel} />
                    {details.respond_form && <RespondPartial selectedLayer={selectedLayer} history={history} match={match} togglePanel={this.togglePanel}>
                        <RespondModal
                            {...details.respond_form}
                            center={coordsToLatLng(details.center.coordinates)}
                            base_layer={details.base_layer}
                            beforeOpen={this.beforeOpen}
                        />
                    </RespondPartial>}
                </div>

                <PageDefaultMap className="map"
                    ref={this.map}
                    activeLayers={activeLayers}
                    details={details}
                    maxBounds={maxBounds}
                    selectedLayer={selectedLayer}
                    history={history}
                    match={match}
                >

                    {details.introduction &&
                        <IntroTour
                            button_text={details.introduction_button}
                            autoplay={details.autoplay}
                            {...details.introduction}
                        />
                    }
                    {details.respond_form &&
                        <RespondOverlay
                            toggleMapElements={this.toggleMapElements}
                            respond_form={details.respond_form}
                            base_layer={details.base_layer}
                            selectedLayer={selectedLayer}
                        />
                    }
                    <ResponseLayer selectedLayerId={selectedLayer.id} history={history} match={match} togglePanel={this.togglePanel} />

                    <POILayer parentPage={match.path} />


                    {/* show only when there is more than 1 forground layer or more than 1 backgroundlayer */}
                    {(mapLayers > 1 || activeLayers.length > 1) &&
                        <MapLayerContainer
                            togglePanel={this.togglePanel}
                            toggleZoom={details.toggle_zoom}
                            mapLayers={mapLayers}
                            selectedLayer={selectedLayer}
                            maxBounds={maxBounds}
                            setSelectedLayer={this.setSelectedLayer} />
                    }


                    <Route
                        path={`${match.path}/response/:respond_id`}
                        render={(props) => (
                            <React.Fragment>
                                <RespondPartialController selectedLayer={selectedLayer} responses={responses} {...props} />
                            </React.Fragment>
                        )}
                    />
                </PageDefaultMap>


            </main>
                <div className='wb-map__footer'>
                    <ButtonGroup>
                        <Button className='border-0' outline={this.state.panelOpen !== 'information'} onClick={(e) => this.togglePanel('information')}>
                            <FontAwesomeIcon title="Information" icon={faInfoCircle} />
                            Informatie
                        </Button>
                        {/* show only when there is more than 1 forground layer or more than 1 backgroundlayer */}
                        {(mapLayers > 1 || activeLayers.length > 1) &&
                            <Button className='border-0' outline={this.state.panelOpen !== 'layers'} onClick={(e) => this.togglePanel('layers')}>
                                <FontAwesomeIcon title="Maplayers" icon={faLayerGroup} />
                                Kaartlagen
                            </Button>
                        }
                        <Button className='border-0' outline={this.state.panelOpen !== 'responses'} onClick={(e) => this.togglePanel('responses')}>
                            <FontAwesomeIcon title="Responses" icon={faComment} />
                            Reacties</Button>
                    </ButtonGroup>
                </div></React.Fragment> : <Loader />
        );
    }
}

// withRouter should be first to keep connection with the base router
export default withRouter(withPage(inject('MapLayerStore', 'ResponseStore')(observer(MapPage))));