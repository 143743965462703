import React from "react";
import { FormattedMessage  } from "react-intl";
import { Helmet } from "react-helmet"

const NotFound = () => {
    return (
        <React.Fragment>
        <Helmet>
            <title>Pagina niet gevonden</title>
        </Helmet>
        <div className="container mt-4 text-center">
            <h1><FormattedMessage id="404_title" /></h1>
            <p><FormattedMessage id="404_text" /></p>
        </div>
        </React.Fragment>
    );
};

export default NotFound;