import SVGIcons from 'components/svgIcons/svgIcons';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

export default function RespondButton(props) {
    return <Button data-test="btn-respond" color="primary" className="btn-no-icon d-block w-100 text-center mb-3" {...props}>
        <SVGIcons className="mr-2" name="bubble" width={32} height={32} fill="currentColor" />
        <span>{props.children}</span>
    </Button>
}

RespondButton.propTypes = {
    children: PropTypes.string
}