import PropTypes from 'prop-types';
import { featureLayer } from 'esri-leaflet';
import { withLeaflet, MapLayer } from "react-leaflet";

class EsriFeatureLayer extends MapLayer {
    onEachFeature(feature, layer) {
        layer.on({
            click: () => this.props.onFeatureClick(feature)
        });
    }
    createLeafletElement({ layer }) {
        const options = {
            url: `${layer.url}/${layer.token ? `?token=${layer.token}` : ""}`,
            onEachFeature: this.onEachFeature.bind(this),
            ...this.props
        };
        const newLayer =  new featureLayer(options);
        newLayer.bringToBack();
        return newLayer;
    }
}

EsriFeatureLayer.propTypes = {
    layer: PropTypes.shape({
        url: PropTypes.string.isRequired,
        token: PropTypes.string
    }).isRequired,
    onFeatureClick: PropTypes.func
};

export default withLeaflet(EsriFeatureLayer);