import React from 'react';
import PropTypes from "prop-types";
import {Jumbotron} from 'reactstrap';

function JumbotronBlock(content) {

    let jumbotronTheme = "";
    if(content.data.jumbotron_theme.select_theme) {
        jumbotronTheme = "theme-"+content.data.jumbotron_theme.select_theme;
    }

    return (
        <Jumbotron className={jumbotronTheme+" mb-0 text-center"}>
            <div className="rte" dangerouslySetInnerHTML={{__html: content.data.text}}>
            </div>
        </Jumbotron>
    );
}

JumbotronBlock.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.string
    })
};
export default JumbotronBlock;