import { observable, action, decorate, runInAction } from "mobx";
import api from "services/api";

/**
 * Mobx store to load and control Map layers
 */
export class EnqueteStore {
    success = false;
    loading = false;
    error = '';

    constructor() {
        this.api = api;
    }

    addSubmission = async (formData, page_id) => {
        let data = {
            form_data: formData,
            page: page_id
        };
        data.form_data = JSON.stringify(data.form_data, null, 2);
        try {
            await this.api.Enquete.add(data);

            runInAction(() => {
                this.success = true;
                this.loading = false;
            });

        } catch (error) {
            runInAction(() => {
                this.success = false;
                this.error = error.response.data;
            });
        }
    };
}

decorate(EnqueteStore, {
    success: observable,

    addSubmission: action
});


export default new EnqueteStore();