import React from 'react';
import PropTypes from "prop-types";
import {Card, CardText, CardBody, CardTitle, CardSubtitle} from 'reactstrap';
import {Link} from "react-router-dom";
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";

function CardBlock(content) {

    let image_hyperlink = content.card.image_selector.clickable_image.hyperlink_intern;

    let styling = (content.styling) ? content.styling : ' col-md-6 '; 

    return (
        <div className={" mb-3 " + styling}>
            <Card className={"fadeIn  " +content.layout}>
                {(image_hyperlink && content.card  ) ? (
                    <Link className="d-block card-image-wrapper img-animate " to={image_hyperlink.meta.slug}>
                        <ResponsiveImage 
                            alt ={content.card.image_selector.attribution}
                            image={content.card.image_selector.image}
                            className="card-img-top"
                        />
                    </Link>
                ):(
                    <ResponsiveImage 
                        alt ={content.card.image_selector.attribution}
                        image={content.card.image_selector.image}
                        className="card-img-top"
                    />
                )}

                <CardBody>
                    {content.card.head !== '' &&
                        <CardTitle tag='h3'>{content.card.head}</CardTitle>
                    }
                    {content.card.subtitle !== '' &&
                        <CardSubtitle>{content.card.subtitle}</CardSubtitle>
                    }
                    {content.card.text !== '' &&
                        <CardText>{content.card.text}</CardText>
                    }
                    {(image_hyperlink && content.card.button_text)  &&
                        <Link to={image_hyperlink.meta.slug} className="btn btn-secondary">{content.card.button_text}</Link>
                    }
                </CardBody>
            </Card>
        </div>
    );
}

CardBlock.propTypes = {
    content: PropTypes.shape({
        card: PropTypes.shape({
            head: PropTypes.string,
            text: PropTypes.string,
            subtitle: PropTypes.string,
            button_text: PropTypes.string,
            block_theme : {
                select_theme: PropTypes.string
            },
            margin_bottom : {
                select_margin_bottom: PropTypes.string
            }
        })
    })
};
export default CardBlock;