import React, {Fragment, Children, cloneElement} from "react";
import PropTypes from 'prop-types';
import {MapComponent, withLeaflet} from "react-leaflet";

class ControlledLayers extends MapComponent {
    constructor(props) {
        super(props);
        this.controlProps = {
            addBaseLayer: this.addBaseLayer.bind(this),
            addOverlay: this.addOverlay.bind(this),
            leaflet: props.leaflet,
            removeLayer: this.removeLayer.bind(this),
            removeLayerControl: () => null
        }
    }

    componentWillUnmount() {
        setTimeout(() => {
            super.componentWillUnmount()
        }, 0)
    }

    addBaseLayer(layer, name, checked = false) {
        if (checked && this.props.leaflet.map != null) {
            this.props.leaflet.map.addLayer(layer)
        }
    }

    addOverlay(layer, name, checked = false) {
        if (checked && this.props.leaflet.map != null) {
            this.props.leaflet.map.addLayer(layer)
        }
    }

    removeLayer(layer) {
        if (this.props.leaflet.map != null) {
            this.props.leaflet.map.removeLayer(layer)
        }
    }

    render() {
        const children = Children.map(this.props.children, child => {
            return child ? cloneElement(child, this.controlProps) : null
        });
        return <Fragment>{children}</Fragment>
    }
}

ControlledLayers.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    leaflet: PropTypes.shape({
        map: PropTypes.object
    })
};

export default withLeaflet(ControlledLayers);