import React, { useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from "prop-types";

const SimpleModal = ({ open, closeModal, title, children }) => {
    const inputRef = useRef(null);

    const handleOpen = () => inputRef.current.focus();

    return <Modal onOpened={handleOpen} autoFocus={true} size="sm" isOpen={open} toggle={closeModal} role="dialog" aria-modal="true">
        <dialog open>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody dangerouslySetInnerHTML={{ __html: children }} />
            <ModalFooter>
                <Button color="success" innerRef={inputRef} autoFocus={true} onClick={closeModal}>OK</Button>
            </ModalFooter>
        </dialog>
    </Modal>;
};

SimpleModal.propTypes = {
    closeModal: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.string
};

export default SimpleModal;