import { observable, action, decorate, runInAction } from "mobx";
import api from "services/api";

const defaultVote = {
    text: "",
    name: "",
    email: "",
    privacy: false,
};

/**
 * Mobx store to load and control votes
 */
export class VoteStore {
    /**
     * Vote store loading state
     * @type {boolean}
     */
    loading = false;
    /**
     * Array of all vote objects
     * @type {Array}
     */
    votes = [];
    /**
     * Error message
     * @type {Object}
     */
    error = {};
    /**
     * Success
     * @type {boolean}
     */
    success = false;
    /**
     * Response object
     * @type {{involvement: {value: null}, mood: null, name: string, privacy: boolean, theme: {value: null}, text: string, position: null, poi: null, email: string, zip_code: string}}
     */
    vote = defaultVote;

    constructor() {
        this.api = api;
    }

    /**
     * Get all votes from the database
     * @return {Promise<T | never>}
     */
    getAllVotes = async () => {
        this.loading = true;
        try {
            let votes = await this.api.Vote.all();

            runInAction(() => {
                this.loading = false;
                this.votes = votes;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error;
            })
        }
    };

    /**
     * Adds a new vote
     * @return {Promise<T | never>}
     */
    addVote = async () => {
        this.loading = true;
        this.success = false;
        try {
            await this.api.Vote.add(this.vote);

            runInAction(() => {
                this.success = true;
                this.loading = false;
                this.clearVote();
                this.getAllVotes();
            });

        } catch (error) {
            runInAction(() => {
                this.error = error.response.data;
            });
        }
    };

    /**
     * Clears the responding state except position
     */
    clearVote = () => {
        this.vote = {
            ...defaultVote,
            poi: this.vote.poi
        };
        this.error = {};
    };

    /**
     * Sets key on the response object with the given value
     * @param key String
     * @param value Any
     */
    updateProperty = (key, value) => {
        this.vote[key] = value;
    };
}


decorate(VoteStore, {
    loading: observable,
    votes: observable,
    error: observable,
    vote: observable,

    getAllVotes: action,
    addVote: action,
    clearVote: action,
    updateProperty: action,
    getVotesByPOIId: action
});

export default new VoteStore();