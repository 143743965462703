import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import LoginForm from "components/LoginForm/LoginForm";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";


export class LoginPage extends Component {
    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                modal: PropTypes.bool,
                from: PropTypes.string
            })
        }),
        history: PropTypes.shape({
            push: PropTypes.func
        })
    }
    render() {
        const { location: { state = {} }, history } = this.props;
        const onFinished = (success = false) => {
            if (success && state && state.from) {
                history.push(state.from)
            } else {
                history.push('/')
            }
        };
        return <>
            <Helmet>
                <title>Login</title>
            </Helmet>

            <main id="main-content">
                <Container className="pt-4">
                    <Row className="justify-content-center">
                        <Col xs="12" md="8">
                            <div className="theme-secondary p-3 p-sm-4" >
                                <h1>Inloggen</h1>
                                <LoginForm onFinished={onFinished} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    };
}

export default withRouter(observer(LoginPage))
