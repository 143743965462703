import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

function MerBreadcrumbs({ node }) {
    const activeChild = node.children && node.children.find(node => node.toggled || node.active)
    return (
        <>
            {activeChild &&
                <MerBreadcrumbs node={activeChild} />
            }
            {(node.active || !node.url) ? <span title={node.name}>{node.name}</span> : <span><Link to={node.url}>{node.name}</Link></span>}
        </>
    );
}

MerBreadcrumbs.propTypes = {
    node: PropTypes.shape({
        toggled: PropTypes.bool,
        active: PropTypes.bool,
        name: PropTypes.string,
        children: PropTypes.array,
        url: PropTypes.string
    })
}

export default MerBreadcrumbs;