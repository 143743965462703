import PageStore from "./Page/PageStore";
import CommonStore from "./Common/CommonStore"
import ResponseStore from "./Response/ResponseStore";
import MapLayerStore from "./Map/MapLayerStore";
import VoteStore from "./Vote/VoteStore";
import EnqueteStore from "./Enquete/EnqueteStore";
import MerStore from "./MER/MerStore";
import userStore from "./User/UserStore";
import authStore from "./Auth/AuthStore";
import searchStore from "./Search/SearchStore";

const stores = {
    PageStore,
    MapLayerStore,
    CommonStore,
    ResponseStore,
    VoteStore,
    EnqueteStore,
    MerStore,
    userStore,
    authStore,
    searchStore
};

export default stores;