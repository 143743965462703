import React, { Component } from "react"
import { Collapse, Nav, Navbar, NavbarToggler, NavItem, Container, DropdownToggle, DropdownMenu, UncontrolledDropdown, Button, DropdownItem } from "reactstrap"
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import { FormattedMessage } from "react-intl";
import { config, site_title } from "utils/envHelpers";
import SearchForm from "components/SearchForm/SearchForm";
import { inject, observer } from "mobx-react";
import SVGIcons from "../../svgIcons/svgIcons";

let logo;
try {
    logo = require("../../../assets/themes" + config.application.logo).default;
} catch (e) {
    logo = false;
}

let favicon;
try {
    favicon = require("../../../assets/themes" + config.application.favicon).default;
} catch (e) {
    favicon = false;
}

let manifest;
try {
    manifest = require("../../../assets/themes" + config.application.manifest).default;
} catch (e) {
    manifest = false;
}

/**
 * Renders the main menu based on a set of route items
 */
const navbarConfig = config.navbar;

class MainMenu extends Component {
    static propTypes = {
        rootPage: PropTypes.object,
        PageStore: PropTypes.shape({
            details: PropTypes.object,
            getPathWithParent: PropTypes.func
        }),
        userStore: PropTypes.shape({
            currentUser: PropTypes.object
        }),
        authStore: PropTypes.shape({
            logout: PropTypes.func
        }),
        /**
         * Array of menu items
         */
        items: PropTypes.arrayOf(PropTypes.shape({
            /**
             * Object with menu item metadata
             */
            meta: PropTypes.shape({
                /**
                 * Slug that is used in the react-router Link
                 */
                slug: PropTypes.string
            }),
            /**
             * Visible title
             */
            title: PropTypes.string,
            /**
             * Unique id for array key
             */
            id: PropTypes.number
        })).isRequired
    };

    state = {
        isOpen: false,
        isSearchOpen: false
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    };

    setSearchOpen = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen })
    }

    goToSearchPage = (searchString) => {
        this.setState({ isSearchOpen: false })
    }

    showInMenu = (item) => {
        return !item.meta.has_restrictions || this.props.userStore.currentUser;
    }

    render() {
        const { rootPage, userStore, authStore, PageStore: { getPathWithParent }, items = [] } = this.props;

        return (
            <>
                {favicon &&
                    <Helmet>
                        <link
                            rel="shortcut icon"
                            href={require("../../../assets/themes" + config.application.favicon).default || null}
                            data-react-helmet="true"
                        />
                    </Helmet>
                }
                {manifest &&
                    <Helmet>
                        {/* <link rel="manifest" href={manifest} /> */}
                    </Helmet>
                }

                <Navbar fixed={navbarConfig.fixedPosition} className={navbarConfig.cssClasses} expand={navbarConfig.expand}>
                    <Container fluid={navbarConfig.fullWidth}>
                        <Button href="#main-content" className="skip-link">Overslaan en naar de inhoud gaan</Button>

                        <Link to="/" className="navbar-brand">
                            {logo ? (
                                <img src={logo} className="img-fluid" alt={site_title + ', ga naar homepage'} />
                            ) : (
                                <span>{site_title}</span>
                            )}
                        </Link>

                        <Collapse isOpen={this.state.isOpen} id="main-nav" navbar>
                            <Nav navbar className="w-100" horizontal={navbarConfig.menuAlignment}>
                                {items.filter(item => (item.id === rootPage.id || (item.meta.parent && item.meta.parent.id === rootPage.id)) && this.showInMenu(item)).map((item) => (
                                    (items.filter(subItem => item.id !== rootPage.id && subItem.meta.parent && subItem.meta.parent.id === item.id && this.showInMenu(item)).length > 0 ? (

                                        <UncontrolledDropdown key={item.id} nav inNavbar>
                                            <DropdownToggle nav caret>
                                                {item.title}
                                            </DropdownToggle>
                                            <DropdownMenu right={!navbarConfig.dropdownAlignment ? true : false} >
                                                {item.meta.type !== "pages.MenuGroup" && <>
                                                    <NavLink key={item.id} className="text-decoration-none" to={getPathWithParent(item)} >
                                                        <DropdownItem tag="span">{item.title}</DropdownItem>
                                                    </NavLink>
                                                </>
                                                }
                                                {items.filter(subItem => subItem.id !== rootPage.id && subItem.meta.parent.id === item.id).map((subItem) => (
                                                    <NavLink
                                                        onClick={this.state.isOpen ? this.toggle : null}
                                                        key={subItem.id}
                                                        className="text-decoration-none"
                                                        to={getPathWithParent(subItem)} >
                                                        <DropdownItem tag="span">{subItem.title}</DropdownItem>
                                                    </NavLink>))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    ) : (
                                        < NavItem key={item.id} >
                                            <NavLink
                                                onClick={this.state.isOpen ? this.toggle : null}
                                                exact={item.id === rootPage.id}
                                                to={getPathWithParent(item)}
                                                className="nav-link"
                                                activeClassName="active">
                                                {item.title}
                                            </NavLink>
                                        </NavItem>
                                    ))

                                ))}
                                {items.find(item => item.meta.has_restrictions) && <>
                                    {userStore.currentUser ?
                                        <NavItem onClick={() => { authStore.logout(); this.toggle(); }} className="nav-link"><FormattedMessage id="mainmenu_logout_text" /></NavItem> :
                                        <NavItem >
                                            <NavLink
                                                to={`/login`}
                                                className="nav-link"
                                                onClick={this.toggle}
                                            >
                                                <FormattedMessage id="mainmenu_login_text" />
                                            </NavLink>
                                        </NavItem>
                                    }
                                </>}
                            </Nav>
                        </Collapse>

                        {navbarConfig.togglerPosition === "left" &&
                            <NavbarToggler onClick={this.toggle} aria-label="toggle mobile menu" aria-controls="main-nav" />
                        }
                        <Nav id="top-nav" className="search-trigger">
                            <Button name="searchtrigger" color="link" aria-expanded={this.state.isSearchOpen} onClick={() => this.setSearchOpen(!this.state.isSearchOpen)} >
                                <SVGIcons name="search" width={26} height={26} title="Zoeken" fill="currentColor" />
                            </Button>
                        </Nav>

                        {navbarConfig.togglerPosition === "right" &&
                            <NavbarToggler onClick={this.toggle} aria-label="toggle mobile menu" aria-controls="main-nav" />
                        }

                    </Container>

                </Navbar>
                <SearchForm isOpen={this.state.isSearchOpen} onSearch={this.goToSearchPage} />
            </>
        )
    };
}



export default inject("userStore", "authStore", "PageStore")(observer(MainMenu))
