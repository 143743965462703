import {withLeaflet, GridLayer} from "react-leaflet";
import PropTypes from "prop-types";
import {TiledMapLayer} from "esri-leaflet";

class BaseMapLayer extends GridLayer {
    static propTypes = {
        url: PropTypes.string
    };

    createLeafletElement(props) {
        return new TiledMapLayer({url: props.url, maxNativeZoom: 14, maxZoom: 20});
    }

    updateLeafletElement(fromProps, toProps) {
        super.updateLeafletElement(fromProps, toProps);
        if (toProps.url !== fromProps.url) {
            this.leafletElement.setUrl(toProps.url)
        }
    }
}

export default withLeaflet(BaseMapLayer)