/* eslint-disable no-extra-boolean-cast */
import React from "react";
import PropTypes from "prop-types";


function CommentRadio({value, error, name, label, onChange, children}) {
    return <label className="svgRadio form-check-label" name={name} htmlFor={label}>
        <input className={`form-check-input ${!!error ? "is-invalid" : ""}`} type="radio"
               value={value} id={label} onChange={() => onChange(name, value)} name={name}/>
        {children}
    </label>
}

CommentRadio.propTypes = {
    value: PropTypes.string,
    error: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    children: PropTypes.node
}

export default CommentRadio;