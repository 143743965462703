import React from 'react';
import PropTypes from "prop-types";

function Paragraph(content) {


    return (
        <div className="rte" dangerouslySetInnerHTML={{__html: content.data.text}}/>
    );
}

Paragraph.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({
            text: PropTypes.string,
            block_theme : {
                select_theme: PropTypes.string
            },
            margin_bottom : {
                select_margin_bottom: PropTypes.string
            }
        })
    })
};
export default Paragraph;