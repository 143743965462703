import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { config } from 'utils/envHelpers';
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";

function fullWidthRows() {
    if (config.hero.defaults.fullWidthRows === true) {
        return 'container-fluid px-0 ';
    } else {
        return 'container px-0 ';
    }
}

function Hero(content) {
    return (
        <div className={fullWidthRows() + ' hero_block'}>
            <div className="row no-gutters">
                <div className="col-sm-12 w-100 mb-0">
                    {content.large &&
                        <>
                            <ResponsiveImage image={content} alt={content.large.alt} className="hero_block-visual" fullWidth={true} />

                            <div className="hero_block-text">
                                {content.hero_head &&
                                    <h1 className="hero_block-head">{content.hero_head}</h1>
                                }
                                {content.hero_text &&
                                    <p className="hero_block-head-text mb-0">{content.hero_text}</p>
                                }
                                {content.cta_link &&
                                    <Link to={content.cta_link.meta.slug}
                                        className="hero_block-link btn btn-primary btn-lg">{content.cta_text}</Link>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

Hero.propTypes = {
    hero_image: PropTypes.shape({
        url: PropTypes.string
    }),
    hero_head: PropTypes.string,
    hero_text: PropTypes.string,
    cta_link: PropTypes.shape({
        meta: PropTypes.shape({
            slug: PropTypes.string
        })
    }),
    cta_text: PropTypes.string
};

export default Hero;