import React, { Component } from 'react';
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import {
    Carousel,
    CarouselItem,
    CarouselCaption
} from 'reactstrap';
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage";


class CarouselBlock extends Component {
    state = { activeIndex: 0 };

    static defaultProps = {
        autoPlay: false
    };

    static propTypes = {
        items: MobxPropTypes.observableArray,
        autoPlay: PropTypes.bool
    };

    onExiting = () => {
        this.animating = true;
    };

    onExited = () => {
        this.animating = false;
    };

    next = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    };

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    };

    render() {

        /*fallback for IE*/
        (function () {
            function CustomEvent(event, params) {
                params = params || { bubbles: false, cancelable: false, detail: undefined };
                var evt = document.createEvent('CustomEvent');
                evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
                return evt;
            }

            CustomEvent.prototype = window.Event.prototype;

            window.CustomEvent = CustomEvent;
        })();


        const { activeIndex } = this.state;
        const { items, autoPlay } = this.props;
        const activeItems = items.filter(item => item.attribution !== '');

        /*structure between carousel on poi and component carousel is not the same*/
        const slides = activeItems.map((item, index) => {
            let image = (item.image ? item.image : item);
            return (
                <CarouselItem
                    onExiting={item.onExiting}
                    onExited={item.onExited}
                    key={index}
                >
                    <ResponsiveImage
                        alt={item.attribution}
                        image={image}
                        fullWidth={true}
                    />
                    <CarouselCaption captionText={<p dangerouslySetInnerHTML={{ __html: item.description }} />} captionHeader={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
                interval={autoPlay}
            >
                {slides}
                {slides.length > 1 && <>
                    <button className="carousel-control-prev" onClick={(e) => { this.previous() }}>
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </button>
                    <button className="carousel-control-next" onClick={(e) => { this.next() }}>
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">next</span>
                    </button>
                    <div className="carousel-indicators">
                        {activeItems.map((item, index) =>
                            <button key={index} className={activeIndex === index && 'active' } onClick={() => { this.goToIndex(index) }}>{index+1}</button>
                        )}
                    </div>
                </>}
            </Carousel>
        );
    }
}

export default CarouselBlock;